import React from 'react';
import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { ProposalType } from '../../../../common/enums/index';
import { ProposalInput } from '../input.interface';
import { TechnicalData } from './technical-data';
import { CommercialData } from './commercial-data';
import { sectionSubtitleStyles } from '../../../../common/styles/form-styles';

export function TechnicalAndCommercialData({
  register,
  control,
  proposalType,
  isLocalProject,
  errors,
  isReadOnly,
}: {
  register: UseFormRegister<ProposalInput>;
  control: Control<ProposalInput, any>;
  proposalType: ProposalType;
  isLocalProject: boolean;
  errors: Partial<FieldErrorsImpl<ProposalInput>>;
  isReadOnly: boolean;
}) {
  return (
    <Container
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '20px 520px',
        paddingTop: '50px',
      }}
    >
      <Container
        sx={{
          gridColumn: '1/2',
          gridRow: '1/2',
          alignSelf: 'center',
          paddingBottom: '20px',
        }}
      >
        <Typography sx={sectionSubtitleStyles}>
          INFORMAÇÕES COMERCIAIS
        </Typography>
      </Container>
      <Container
        sx={{
          gridColumn: '2/3',
          gridRow: '1/2',
          alignSelf: 'center',
          paddingBottom: '20px',
        }}
      >
        <Typography sx={sectionSubtitleStyles}>INFORMAÇÕES TÉCNICAS</Typography>
      </Container>
      <Container
        sx={{
          gridColumn: '1/2',
          gridRow: '2/6',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CommercialData
          register={register}
          isLocalProject={isLocalProject}
          control={control}
          errors={errors}
          isReadOnly={isReadOnly}
        ></CommercialData>
      </Container>
      <Container
        sx={{
          gridColumn: '2/3',
          gridRow: '2/6',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TechnicalData
          proposalType={proposalType}
          control={control}
          errors={errors}
          isReadOnly={isReadOnly}
        ></TechnicalData>
      </Container>
    </Container>
  );
}
