import React from 'react';
import { Box } from '@mui/material';
import logo from './logo.svg';

export function Logo() {
  return (
    <Box
      sx={{
        width: 160,
        height: 30,
      }}
    >
      <img src={logo} alt="Gerar Livre" />
    </Box>
  );
}
