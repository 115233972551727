import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import emailValidator from 'email-validator';
import useAuth from '../../common/hooks/auth';
import useGeneralAppState from '../../common/hooks/general-app-state';

type Inputs = {
  email: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};

export function ChangePasswordForm() {
  const { setIsLoading, isLoading } = useGeneralAppState();
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm<Inputs>();

  const { handleChangePassword } = useAuth();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsLoading(true);
    await handleChangePassword({
      email: data.email,
      password: data.password,
      newPassword: data.newPassword,
    });
    setIsLoading(false);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: '75px 0',
        paddingRight: '75px',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          alignItems: 'flex-end',
          justifyContent: 'space-evenly',
          gap: '15px',
        }}
      >
        <TextField
          label="Usuário"
          variant="filled"
          size="small"
          {...register('email', {
            required: 'Campo obrigatório',
            validate: {
              isValidEmail: (email) =>
                emailValidator.validate(email) || 'Insira um email válido',
            },
          })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        ></TextField>
        <TextField
          label="Senha antiga"
          variant="filled"
          type="password"
          size="small"
          {...register('password', { required: 'Campo obrigatório' })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        ></TextField>
        <TextField
          label="Nova Senha"
          variant="filled"
          type="password"
          size="small"
          {...register('newPassword', { required: 'Campo obrigatório' })}
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword?.message}
        ></TextField>
        <TextField
          label="Confirme sua nova senha"
          variant="filled"
          type="password"
          size="small"
          {...register('confirmNewPassword', {
            required: 'Campo obrigatório',
            validate: {
              passwordConfirmationMatches: (confirmNewPassword) => {
                const newPassword = getValues('newPassword');
                return (
                  newPassword === confirmNewPassword ||
                  'As senhas não são iguais'
                );
              },
            },
          })}
          error={Boolean(errors.confirmNewPassword)}
          helperText={errors.confirmNewPassword?.message}
        ></TextField>
        <Button
          variant="contained"
          color="warning"
          type="submit"
          disabled={isLoading}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
}
