import { getApiInstance } from '../api';

export const getAdminSettings = async (token: string) => {
  const api = getApiInstance(token);
  try {
    const result = await api.get('/admin-settings');

    return { success: true, data: result.data };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      message: `Erro ao carregar parâmetros. ${(error as Error).message}`,
    };
  }
};
