import axios from 'axios';
import { CreatePowerPlantInputs } from '../../../pages/power-plant';
import { getApiInstance } from '../api';

export const createPowerPlant = async ({
  token,
  userInput,
}: {
  token: string;
  userInput: CreatePowerPlantInputs;
}) => {
  const api = getApiInstance(token);
  try {
    await api.post(`/power-plants`, userInput);

    return { success: true };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error) && error.response?.status) {
      if (error.response?.status === 400) {
        const apiMessage = error?.response?.data?.message;
        const arrayOfMessages =
          typeof apiMessage === 'string' ? [apiMessage] : apiMessage || [];
        const message = ['Erro ao criar usina.', ...arrayOfMessages].join(' ');
        return {
          success: false,
          message,
        };
      }
      return {
        success: false,
        message: `Erro ao criar usina. ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao criar usina. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
