import axios from 'axios';
import { CreateIntegratorInputs } from '../../../pages/integrator';
import { getApiInstance } from '../api';

export const createIntegrator = async ({
  token,
  userInput,
}: {
  token: string;
  userInput: CreateIntegratorInputs;
}) => {
  const api = getApiInstance(token);
  try {
    await api.post(`/register`, userInput);

    return { success: true };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error) && error.response?.status) {
      if (error.response?.status === 400) {
        const apiMessage = error?.response?.data?.message;
        const arrayOfMessages =
          typeof apiMessage === 'string' ? [apiMessage] : apiMessage || [];
        const message = ['Erro ao criar integrador.', ...arrayOfMessages].join(
          ' ',
        );
        return {
          success: false,
          message,
        };
      }
      return {
        success: false,
        message: `Erro ao criar integrador. ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao criar integrador. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
