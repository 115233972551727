import { getApiInstance } from '../api';

export const bulkDeletePowerDistributionCompanies = async ({
  token,
  ids,
}: {
  token: string;
  ids: string[];
}) => {
  const api = getApiInstance(token);
  try {
    const searchParams = new URLSearchParams(
      // @ts-ignore
      {
        ids,
      },
    );
    await api.delete(
      `/power-distribution-companies?${searchParams.toString()}`,
    );

    return { success: true };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `Erro ao deletar proposta. ${(error as Error).message}`,
    };
  }
};
