import React from 'react';
import { useTheme, Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { config } from '../../common/config/base';
import useAuth from '../../common/hooks/auth';
import { Role } from '../../common/enums/roles.enum';

export function HeaderLinks({
  refAbout,
  refContact,
  refServices,
  handleScroll,
}: any) {
  const theme = useTheme();
  const { user } = useAuth();
  const { pathname } = useLocation();

  const linkStyle = {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontSize: '1.1em',
    fontWeight: 500,
  };

  const separatorStyle = {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontSize: '1.1em',
    fontWeight: 500,
    lineHeight: '50px',
    padding: '0px 10px 0px 10px',
  };

  const isLoginPage = pathname === config.internalPaths.login;
  const isChangePasswordPage = pathname === config.internalPaths.changePassword;
  const isLandingPage = refAbout && refContact && refServices && handleScroll;
  const isAdmin = user?.role === Role.ADMIN;

  if (isLoginPage || isChangePasswordPage) {
    return <></>;
  }

  if (isLandingPage)
    return (
      <>
        <Link onClick={() => handleScroll(refAbout)} sx={linkStyle}>
          SOBRE
        </Link>
        <Typography sx={separatorStyle}>|</Typography>
        <Link onClick={() => handleScroll(refServices)} sx={linkStyle}>
          SERVIÇOS
        </Link>
        <Typography sx={separatorStyle}>|</Typography>
        <Link onClick={() => handleScroll(refContact)} sx={linkStyle}>
          CONTATO
        </Link>
      </>
    );

  if (isAdmin) {
    return (
      <>
        <Link href={config.internalPaths.adminHome} sx={linkStyle}>
          HOME
        </Link>
        <Typography sx={separatorStyle}>|</Typography>
        <Link
          href={config.internalPaths.powerDistributionCompanies}
          sx={linkStyle}
        >
          CONCESSIONÁRIAS
        </Link>
        <Typography sx={separatorStyle}>|</Typography>
        <Link href={config.internalPaths.adminSettings} sx={linkStyle}>
          OUTROS PARÂMETROS
        </Link>
      </>
    );
  }

  return (
    <>
      <Link href={config.internalPaths.proposal} sx={linkStyle}>
        PROPOSTAS
      </Link>
      <Typography sx={separatorStyle}>|</Typography>
      <Link href={config.internalPaths.userProfile} sx={linkStyle}>
        MEU PERFIL
      </Link>
    </>
  );
}
