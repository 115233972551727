export const maskNumber = (maxLength: number) => (value: string) =>
  value
    ?.replace(/\D/g, '')
    .slice(0, maxLength)
    .replace(/(\d)(\d{2})$/, '$1,$2');
// @TODO: try to fix the replace below. For some reason it makes the value go haywire after 9 digits
// but the regex was tested at regex101 and it seems fine
// .replace(/(?=(\d{3})+(\D))\B/g, '.');

export const unmaskNumber = (value: string) =>
  value?.replace('.', '').replace(',', '.');
