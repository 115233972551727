import axios from 'axios';
import { getApiInstance } from '../api';

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const api = getApiInstance();
  try {
    const response = await api.post('/login', {
      email,
      password,
    });

    return { success: true, ...response.data };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error)) {
      if (
        error.response?.status &&
        error.response?.status >= 400 &&
        error.response?.status < 500
      ) {
        return {
          success: false,
          message: 'Login não autorizado. Credenciais inválidas.',
        };
      }
      return {
        success: false,
        message: `Login não autorizado. Erro no servidor: ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Login não autorizado. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
