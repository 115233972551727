import axios from 'axios';
import { getApiInstance } from '../api';

export const listIntegratorProposals = async ({
  token,
  integratorId,
  page,
}: {
  token: string;
  integratorId: string;
  page: number;
}) => {
  const api = getApiInstance(token);
  try {
    const searchParams = new URLSearchParams({
      integratorId,
      page: String(page),
    });
    const response = await api.get(`/proposals?${searchParams.toString()}`);

    const { data, count } = response.data;

    return { success: true, data, count };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error)) {
      return {
        success: false,
        message: `Erro ao carregar propostas. Erro no servidor: ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao carregar propostas. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
