import React from 'react';
import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Control, FieldErrorsImpl, UseFormGetValues } from 'react-hook-form';
import { ProposalInput } from './input.interface';
import { ControlledMaskedInput } from '../../../components/controlled-masked-input/index';
import {
  formValueToInputValue,
  inputValueToFormValue,
} from '../../../common/helpers/input-value-to-form-value';
import { maskNumber, unmaskNumber } from '../../../common/helpers/number-mask';
import { sectionSubtitleStyles } from '../../../common/styles/form-styles';

export function FinancialData({
  control,
  getValues,
  errors,
  isReadOnly,
}: {
  control: Control<ProposalInput, any>;
  getValues: UseFormGetValues<ProposalInput>;
  errors: Partial<FieldErrorsImpl<ProposalInput>>;
  isReadOnly: boolean;
}) {
  return (
    <Container
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '20px 300px',
        paddingTop: '50px',
        paddingBottom: '100px',
      }}
    >
      <Container
        sx={{
          gridColumn: '1/2',
          gridRow: '1/2',
          alignSelf: 'center',
          paddingBottom: '20px',
        }}
      >
        <Typography sx={sectionSubtitleStyles}>FINANCIAMENTO</Typography>
      </Container>
      <Container
        sx={{
          gridColumn: '1/2',
          gridRow: '2/6',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {[
          {
            name: 'loan.numberOfMonthlyPayments',
            label: 'Prazo de quitação (meses)',
            min: 1,
            max: 240,
            transform: {
              unmask: parseInt,
            },
            validate: {
              isLargerThanGracePeriod: (value: number) => {
                const gracePeriodInMonths = getValues(
                  'loan.gracePeriodInMonths',
                );
                return (
                  value > gracePeriodInMonths ||
                  'Deve ser maior que o período de carência'
                );
              },
            },
            deps: ['loan.gracePeriodInMonths'],
          },
          {
            name: 'loan.gracePeriodInMonths',
            label: 'Prazo de carência (meses)',
            min: 0,
            max: 12,
            transform: {
              unmask: parseInt,
            },
            validate: {
              isLessThanNumberOfInstallments: (value: number) => {
                const numberOfMonthlyPayments = getValues(
                  'loan.numberOfMonthlyPayments',
                );
                return (
                  value < numberOfMonthlyPayments ||
                  'Deve ser menor que o prazo de quitação'
                );
              },
            },
            deps: ['loan.numberOfMonthlyPayments'],
          },
          {
            name: 'loan.annualInterestRate',
            label: 'Juros anual (%)',
            min: 0,
            transform: {
              inputValueToFormValue: inputValueToFormValue(0.01),
              formValueToInputValue: formValueToInputValue(100),
              mask: maskNumber(5),
              unmask: unmaskNumber,
            },
            deps: ['loan.numberOfMonthlyPayments'],
          },
          {
            name: 'loan.downPaymentPercentage',
            label: 'Valor da entrada (%)',
            min: 0,
            transform: {
              inputValueToFormValue: inputValueToFormValue(0.01),
              formValueToInputValue: formValueToInputValue(100),
              mask: maskNumber(5),
              unmask: unmaskNumber,
            },
            deps: ['loan.numberOfMonthlyPayments'],
          },
        ].map((props) => (
          <ControlledMaskedInput
            key={props.label}
            {...props}
            control={control}
            errors={errors}
            isRequired
            isReadOnly={isReadOnly}
          />
        ))}
      </Container>
    </Container>
  );
}
