export const inputValueToFormValue =
  (multiplier: number) => (value: string) => {
    const numericValue = parseFloat(value);
    return numericValue * multiplier;
  };

export const formValueToInputValue =
  (multiplier: number) => (value: number) => {
    return (value * multiplier).toFixed(2);
  };
