import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import {
  EditOutlined,
  DownloadOutlined,
  HighlightOff,
  CheckCircleOutline,
  CircleOutlined,
  SimCardDownloadOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { listIntegratorProposals } from '../../../common/api/proposals/list-integrator-proposals';
import useAuth from '../../../common/hooks/auth';
import useGeneralAppState from '../../../common/hooks/general-app-state';
import { deleteProposal } from '../../../common/api/proposals/delete-proposal';
import ConfirmationDialog from '../../../components/confirmation-dialog';
import { config } from '../../../common/config/base';
import { confirmDialogInitialProps } from '../../../components/confirmation-dialog/initial-props';
import { getProposalFilesUrl } from '../../../common/api/proposals/get-file-url';
import { toggleContractSignature } from '../../../common/api/proposals/toggle-contract-signature';
import { ProposalType } from '../../../common/enums';

interface ProposalListDto {
  id: string;
  name: string;
  isContractSigned: boolean;
  proposalType: ProposalType;
}

const iconStyles = { cursor: 'pointer', color: 'black' };

const tableRowStyles = {
  height: 73,
};

function SignContractCell({ style, onClick, isContractSigned, disabled }: any) {
  return (
    <TableCell align="center">
      <IconButton sx={style} onClick={onClick}>
        {isContractSigned ? <CheckCircleOutline /> : <CircleOutlined />}
      </IconButton>
    </TableCell>
  );
}

const proposalTypeMap = new Map([
  [ProposalType.LOCAL_DEVELOPMENT, 'Desenv. Local'],
  [ProposalType.REMOTE_DEVELOPMENT, 'Desenv. Remoto'],
  [ProposalType.PURCHASE, 'Compra'],
]);

export function ProposalTable() {
  const theme = useTheme();
  const params = useParams();
  const { isLoading, setIsLoading, setAppMessage } = useGeneralAppState();
  const { user, handleLogout } = useAuth();
  const [proposalList, setProposalList] = useState<ProposalListDto[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState(
    confirmDialogInitialProps,
  );

  const isAdmin = !!params.integratorId;
  const isUser = !isAdmin;

  const rowsPerPage = 5;
  const emptyRows = isLoading ? rowsPerPage : rowsPerPage - proposalList.length;

  const getProposals = useCallback(async () => {
    if (!user) return;
    const { token } = user;
    const integratorId = params.integratorId ?? user.integratorId;

    setIsLoading(true);
    const {
      success,
      message,
      data,
      count: proposalCount,
    } = await listIntegratorProposals({
      token,
      integratorId,
      page,
    });
    setIsLoading(false);

    if (success) {
      setCount(proposalCount);
      setProposalList(data);
      return;
    }
    setAppMessage({
      title: 'Error',
      content: message as string,
    });
    handleLogout();
  }, [
    handleLogout,
    setAppMessage,
    user,
    setIsLoading,
    page,
    params.integratorId,
  ]);

  useEffect(() => {
    getProposals();
  }, [getProposals]);

  const handleDelete = useCallback(
    async (proposalId: string) => {
      setConfirmationDialogProps(confirmDialogInitialProps);
      setIsLoading(true);
      const { success, message } = await deleteProposal({
        proposalId,
        token: user?.token as string,
      });
      setIsLoading(false);

      if (success) {
        getProposals();
        setAppMessage({
          title: 'Sucesso',
          content: 'A proposta foi deletada.',
        });
        return;
      }

      setAppMessage({
        title: 'Error',
        content: message as string,
      });
    },
    [user, setAppMessage, getProposals, setIsLoading],
  );

  const handleConfirmDelete = useCallback(
    (proposalId: string) => () => {
      setConfirmationDialogProps({
        isVisible: true,
        title: 'Atenção',
        message: 'Deletar proposta? Esta ação não pode ser revertida.',
        handleReject: () =>
          setConfirmationDialogProps(confirmDialogInitialProps),
        handleConfirm: () => handleDelete(proposalId),
      });
    },
    [handleDelete],
  );

  const handleDownloadAttachments = useCallback(
    (proposalId: string) => async () => {
      setIsLoading(true);
      const { success, message, data } = await getProposalFilesUrl({
        token: user?.token as string,
        proposalId,
      });
      setIsLoading(false);

      if (success) {
        window.open(data.fileUrl);
        return;
      }

      setAppMessage({
        title: 'Erro',
        content: message as string,
      });
    },
    [setIsLoading, setAppMessage, user],
  );

  const handleToggleContractSignature = useCallback(
    (proposalId: string) => async () => {
      if (isUser) return;

      setIsLoading(true);
      const { success, message, data } = await toggleContractSignature({
        token: user?.token as string,
        proposalId,
      });
      setIsLoading(false);

      if (success) {
        const { isContractSigned } = data;
        const proposalIndex = proposalList.findIndex(
          (proposal) => proposal.id === proposalId,
        );
        setProposalList([
          ...proposalList.slice(0, proposalIndex),
          { ...proposalList[proposalIndex], isContractSigned },
          ...proposalList.slice(proposalIndex + 1),
        ]);
        return;
      }

      setAppMessage({
        title: 'Erro',
        content: message as string,
      });
    },
    [setIsLoading, setAppMessage, user, proposalList, isUser],
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const tableHeaderSettings = {
    fontWeight: 800,
    color: theme.palette.primary.dark,
  };

  return (
    <Container
      sx={{
        paddingTop: '100px',
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '650px' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={tableHeaderSettings}>Proposta</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography sx={tableHeaderSettings}>Tipo</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography sx={tableHeaderSettings}>
                  {isUser ? 'Editar' : 'Visualizar'}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography sx={tableHeaderSettings}>Baixar PDF</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography sx={tableHeaderSettings}>
                  Excluir Proposta
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography sx={tableHeaderSettings}>
                  Contrato Assinado
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography sx={tableHeaderSettings}>Baixar Anexos</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              proposalList?.map((proposal) => (
                <TableRow
                  sx={{
                    height: `${tableRowStyles.height}px`,
                    '&:nth-of-type(odd)': {
                      backgroundColor: theme.palette.action.hover,
                    },
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                  key={proposal.id}
                >
                  <TableCell
                    sx={{
                      textOverflow: 'ellipsis',
                      maxWidth: '200px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    component="th"
                    scope="row"
                  >
                    {proposal.name}
                  </TableCell>
                  <TableCell align="center">
                    {proposalTypeMap.get(proposal.proposalType)}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      sx={iconStyles}
                      href={config.internalPaths.editProposal.replace(
                        ':proposalId',
                        proposal.id,
                      )}
                    >
                      {isUser ? <EditOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      sx={iconStyles}
                      href={config.internalPaths.proposalPdf.replace(
                        ':proposalId',
                        proposal.id,
                      )}
                    >
                      <DownloadOutlined />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      sx={iconStyles}
                      onClick={handleConfirmDelete(proposal.id)}
                    >
                      <HighlightOff></HighlightOff>
                    </IconButton>
                  </TableCell>
                  <SignContractCell
                    style={
                      isAdmin
                        ? iconStyles
                        : { ...iconStyles, cursor: 'not-allowed' }
                    }
                    disabled={isAdmin ? false : true}
                    isContractSigned={proposal.isContractSigned}
                    onClick={handleToggleContractSignature(proposal.id)}
                  ></SignContractCell>
                  <TableCell align="center">
                    <IconButton
                      sx={iconStyles}
                      onClick={handleDownloadAttachments(proposal.id)}
                    >
                      <SimCardDownloadOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                sx={{
                  height: `${tableRowStyles.height * emptyRows}px`,
                }}
              >
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={Paper}
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        page={page}
        onPageChange={handleChangePage}
      ></TablePagination>
      <ConfirmationDialog {...confirmationDialogProps}></ConfirmationDialog>
    </Container>
  );
}
