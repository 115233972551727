import { getApiInstance } from '../api';

export const deleteIntegrator = async ({
  token,
  integratorId,
}: {
  token: string;
  integratorId: string;
}) => {
  const api = getApiInstance(token);
  try {
    await api.delete(`/integrators/${integratorId}`);

    return { success: true };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `Erro ao deletar proposta. ${(error as Error).message}`,
    };
  }
};
