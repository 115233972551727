import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

import nunoMarques from '../../images/nuno-marques.jpg';
import { Header } from '../header';
import { Footer } from '../footer';
import Loading from '../loading/index';

export function AppContainer({
  title,
  children,
  isLoading,
}: {
  title: string;
  children: JSX.Element;
  isLoading: boolean;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        widht: '100%',
        height: 'calc(100vh - 150px)',
      }}
    >
      <Header></Header>
      <Box
        sx={{
          height: '50px',
          width: '100%',
          background: `linear-gradient(0deg, rgba(232, 232, 232, 0.6), rgba(232, 232, 232, 0.6)), url(${nunoMarques})`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '1.5em',
            padding: '5px',
            fontWeight: 800,
            color: theme.palette.primary.dark,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Box>
      <Footer></Footer>
      <Loading isLoading={isLoading}></Loading>
    </Box>
  );
}
