import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Container } from '@mui/system';
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { config } from '../../common/config/base';
import { ControlledMaskedInput } from '../../components/controlled-masked-input';
import {
  formItemStyles,
  sectionSubtitleStyles,
} from '../../common/styles/form-styles';
import {
  formValueToInputValue,
  inputValueToFormValue,
} from '../../common/helpers/input-value-to-form-value';
import { maskNumber, unmaskNumber } from '../../common/helpers/number-mask';
import useAuth from '../../common/hooks/auth';
import useGeneralAppState from '../../common/hooks/general-app-state';
import { AppContainer } from '../../components/app-container';
import { State } from '../../common/enums/state.enum';
import { getAdminSettings } from '../../common/api/admin-settings/get-admin-settings';
import { saveAdminSettings } from '../../common/api/admin-settings/save-admin-settings';

// @TODO: move interfaces and functions to proper file

const tableTextStyles = {
  fontSize: '1em',
};

const tableCellInputProps = {
  inputProps: {
    style: {
      textAlign: 'center' as 'center',
    },
  },
};

const booleanInputProps = {
  isSelect: true,
  validate: {
    isBoolean: (value: boolean | string) => {
      return typeof value === 'boolean' || 'Campo obrigatório';
    },
  },
  transform: {
    formValueToInputValue: (value: boolean) =>
      typeof value === 'boolean' ? String(value) : '',
    inputValueToFormValue: (value: string) =>
      value === 'true' ? true : value === 'false' ? false : undefined,
  },
  children: [
    { value: String(true), label: 'SIM' },
    { value: String(false), label: 'NÃO' },
  ].map(({ value, label }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  )),
};

const isNotNanValidate = {
  isNotNan: (value: number) => !Number.isNaN(value) || 'Campo obrigatório',
};
const sumAllPaymentDataFromFormValues = (getValues: any) => {
  const paymentData = getValues(
    'paymentData',
  ) as AdminSettingsInputs['paymentData'];
  return sumAllPaymentData(paymentData);
};

const sumAllPaymentData = (paymentData: PaymentData) => {
  const sum = Object.entries(paymentData)
    .filter(([key]) => key !== 'sum')
    .reduce((sum, [, value]) => sum + value, 0);
  return Number(sum.toFixed(4));
};

const paymentDataValidateError =
  'A soma de todas as formas de pagamento deve ser 100%';

const paymentDataValidate = ({
  setPaymentDataSum,
  getValues,
}: {
  setPaymentDataSum: any;
  getValues: any;
}) => ({
  mustComplete100percent: () => {
    const sum = sumAllPaymentDataFromFormValues(getValues);
    setPaymentDataSum(sum);
    return sum === 1 || paymentDataValidateError;
  },
});

interface StateClimateInputs {
  capacityFactor: number;
  solarAnnualProductivity: number;
  averageGti: number;
}

interface ClimateDataInputs {
  [key: string]: StateClimateInputs | number;
}

interface PaymentData {
  contractSignature: number;
  landLeaseContractSignature: number;
  gridConnectionAllowance: number;
  mobilizationToSite: number;
  structureDeliveranceOnSite: number;
  moduleDeliveranceOnSite: number;
  inverterDeliveranceOnSite: number;
  installationsDone: number;
  powerPlantDeliverance: number;
  sum?: number;
}

export interface AdminSettingsInputs {
  paymentData: PaymentData;
  climateData: ClimateDataInputs;
  gridLosses: number;
  organizationAndMaintenancePercentage: number;
  monthlyManagementFeePerUnit: number;
  haPerMWpFactor: number;
  monthlyLandLeasePerHa: number;
  roofPowerPlantSellingPricePerMWp: number;
  powerPlantSellingPricePerMWp: number;
  powerPlantProjectPricePerMWp: number;
  powerPlantGridPricePerMWp: number;
  powerPlantInitialGenerationLoss: number;
  powerPlantContinuousGenerationLoss: number;
  dollarExchangeRate: number;
  ipca: number;
  energyInflation: number;
  taxOnIncome: number;
  incentivizedEnergy: number;
  loanAnnualInterestRate: number;
  loanDownPaymentPercentage: number;
  selicOrTma: number;
  solarElectricalSystemPerformance: number;
  solarElectricalSystemTrackerPerformanceEnhancement: number;
  pisCofins: number;
  inverterSizingFactor: number;
  hoursInAMonth: number;
  numberOfYearsForCashFlow: number;
  commercialSafetyMargin: number;
  blockPowerPlantPurchase: boolean;
  blockPowerPlantRemoteDevelopment: boolean;
  blockPowerPlantLocalDevelopment: boolean;
  proposalWebsite: string;
  proposalInstagram: string;
  proposalEmail: string;
  proposalSignatureName: string;
  proposalPhone: string;
}

const initialData = {};

const parseApiData = (data: AdminSettingsInputs) => ({
  ...data,
  climateData: {
    ...data.climateData,
    ...Object.entries(data.climateData).reduce((acc, [key, values]) => {
      return {
        ...acc,
        [`averageGtiYear${key}`]: (values as StateClimateInputs).averageGti,
      };
    }, {}),
  },
});

const pageTitle = 'outros parâmetros';

//@TODO: Separate sections into different components
export function AdminSettingsForm() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setAppMessage } = useGeneralAppState();
  const [paymentDataSum, setPaymentDataSum] = useState(NaN);
  const [paymentDataSumInputValue, setPaymentDataSumInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    getValues,
  } = useForm<AdminSettingsInputs>({
    mode: 'onChange',
    defaultValues: async () => {
      setIsLoading(true);
      const { success, message, data } = await getAdminSettings(
        user?.token as string,
      );
      setIsLoading(false);

      if (success) {
        if (Object.keys(data).length > 0) {
          const sum = sumAllPaymentData(data.paymentData);
          setPaymentDataSum(sum);
          return parseApiData(data);
        }

        return initialData as AdminSettingsInputs;
      }

      setAppMessage({
        title: 'Erro',
        content: message as string,
      });
      navigate(config.internalPaths.adminHome);
      return {} as AdminSettingsInputs;
    },
  });

  useEffect(() => {
    const sum = Number.isNaN(paymentDataSum)
      ? ''
      : `${(100 * paymentDataSum).toFixed(2)}%`;
    setPaymentDataSumInputValue(sum);
  }, [getValues, paymentDataSum]);

  const handleSave = useCallback(
    async (data: AdminSettingsInputs) => {
      setIsLoading(true);
      const { success, message } = await saveAdminSettings({
        token: user?.token as string,
        userInput: data,
      });
      setIsLoading(false);

      if (success) {
        setAppMessage({
          title: 'Sucesso',
          content: 'Os parâmetros foram atualizados com sucesso.',
        });
        return navigate(config.internalPaths.adminHome);
      }

      setAppMessage({ title: 'Erro', content: message as string });
    },
    [setAppMessage, navigate, user, setIsLoading],
  );

  const onSubmit: SubmitHandler<AdminSettingsInputs> = handleSave;

  return (
    <AppContainer isLoading={isLoading} title={pageTitle.toUpperCase()}>
      <Container>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Container
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: '40px 250px 40px 500px 40px 85px',
              gap: 1,
              gridTemplateAreas: `"powerPlantHeader opexHeader"
              "powerPlant opex"
              "financingHeader commercialHeader"
              "financing commercial"
              "capacityFactorHeader capacityFactorHeader"
              "performance tracker"`,
              paddingTop: '30px',
            }}
          >
            <Container
              sx={{
                gridArea: 'powerPlantHeader',
                alignSelf: 'center',
              }}
            >
              <Typography sx={sectionSubtitleStyles}>USINA</Typography>
            </Container>
            <Container
              sx={{
                gridArea: 'opexHeader',
                alignSelf: 'center',
              }}
            >
              <Typography sx={sectionSubtitleStyles}>OPEX (ANUAL)</Typography>
            </Container>
            <Container
              sx={{
                gridArea: 'commercialHeader',
                alignSelf: 'center',
              }}
            >
              <Typography sx={sectionSubtitleStyles}>
                SOLAR/COMERCIALIZAÇÃO
              </Typography>
            </Container>
            <Container
              sx={{
                gridArea: 'financingHeader',
                alignSelf: 'center',
              }}
            >
              <Typography sx={sectionSubtitleStyles}>
                JUROS FINANCIAMENTO
              </Typography>
            </Container>
            <Container
              sx={{
                gridArea: 'capacityFactorHeader',
                alignSelf: 'center',
              }}
            >
              <Typography sx={sectionSubtitleStyles}>
                FATOR DE CAPACIDADE
              </Typography>
            </Container>
            <Container
              sx={{
                gridArea: 'powerPlant',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'powerPlantSellingPricePerMWp',
                  label: 'Valor da usina (R$/MWp)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(14),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'roofPowerPlantSellingPricePerMWp',
                  label: 'Valor da usina (telhado) (R$/MWp)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(14),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'powerPlantProjectPricePerMWp',
                  label: 'Valor do projeto (R$/MWp)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'powerPlantGridPricePerMWp',
                  label: 'Obra de rede estimado (R$/MWp)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
              ].map(({ ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                />
              ))}
            </Container>
            <Container
              sx={{
                gridArea: 'opex',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'organizationAndMaintenancePercentage',
                  label: 'O&M (% do capex)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'monthlyManagementFeePerUnit',
                  label: 'Gestão do cativo (R$/unidade)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'monthlyLandLeasePerHa',
                  label: 'Valor da locação do terreno (R$/ha)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
              ].map(({ ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                />
              ))}
            </Container>
            <Container
              sx={{
                gridArea: 'financing',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'ipca',
                  label: 'IPCA a.a. (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'energyInflation',
                  label: 'Inflação energética (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'loanAnnualInterestRate',
                  label: 'Valor do juros do financiamento (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'loanDownPaymentPercentage',
                  label: 'Entrada financiamento (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'selicOrTma',
                  label: 'SELIC (Waac) ou TMA (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
              ].map(({ ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                />
              ))}
            </Container>
            <Container
              sx={{
                gridArea: 'commercial',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'dollarExchangeRate',
                  label: 'Cotação do dólar (R$/US$)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'incentivizedEnergy',
                  label: 'Energia incentivada',
                  isSelect: true,
                  children: [
                    { value: 0, label: '0%' },
                    { value: 0.5, label: '50%' },
                    { value: 1, label: '100%' },
                  ].map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  )),
                },
                {
                  name: 'taxOnIncome',
                  label: 'Imposto sobre receita (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'gridLosses',
                  label: 'Perda Rede (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'powerPlantInitialGenerationLoss',
                  label: 'Perda de geração no primeiro ano (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'powerPlantContinuousGenerationLoss',
                  label: 'Perda de geração nos demais anos (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'pisCofins',
                  label: 'PIS/COFINS (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'neededAvailableAreaFor1MWp',
                  label: 'Área disponível necessária para 1MWp (m²)',
                  min: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
              ].map(({ children, ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                >
                  {children}
                </ControlledMaskedInput>
              ))}
            </Container>
            <Container
              sx={{
                gridArea: 'performance',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'solarElectricalSystemPerformance',
                  label: 'Performance (%)',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
              ].map(({ ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                />
              ))}
            </Container>
            <Container
              sx={{
                gridArea: 'tracker',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'solarElectricalSystemTrackerPerformanceEnhancement',
                  label: 'Tracker (%)',
                  min: 0,
                  validate: isNotNanValidate,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                  tooltip: {
                    title: 'Percentual adicional em relação ao fixo',
                  },
                },
              ].map(({ ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                />
              ))}
            </Container>
          </Container>
          <Container
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr repeat(3, 2fr)',
              gridTemplateRows: 'auto',
              gap: 1,
              paddingTop: '30px',
            }}
          >
            <Container
              sx={{
                gridColumn: '1/2',
                alignSelf: 'center',
              }}
            >
              <Typography sx={tableTextStyles}>ESTADO</Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '2/3',
                alignSelf: 'center',
              }}
            >
              <Typography sx={tableTextStyles}>
                GTI MÉDIO [KWh/m²*Ano]
              </Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '3/4',
                alignSelf: 'center',
              }}
            >
              <Typography sx={tableTextStyles}>
                PRODUTIVIDADE [KWh/kWp*Ano]
              </Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '4/5',
                alignSelf: 'center',
              }}
            >
              <Typography sx={tableTextStyles}>
                FATOR DE CAPACIDADE [%]
              </Typography>
            </Container>
            {Object.keys(State).map((state) => (
              <Container
                key={state}
                sx={{
                  gridColumn: '1/5',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{
                    ...tableTextStyles,
                    boxSizing: 'border-box',
                    width: '50px',
                  }}
                >
                  {state}
                </Typography>
                <ControlledMaskedInput
                  isRequired
                  name={`climateData.averageGtiYear${state}`}
                  textFieldStyles={{
                    paddingLeft: '10px',
                    width: '20%',
                  }}
                  inputProps={tableCellInputProps}
                  control={control}
                  errors={errors}
                  min={0}
                  validate={isNotNanValidate}
                  transform={{
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  }}
                />
                <Tooltip title="Não atualiza em tempo real">
                  <TextField
                    sx={{
                      paddingLeft: '50px',
                    }}
                    InputProps={tableCellInputProps}
                    variant="filled"
                    size="small"
                    disabled
                    value={
                      getValues(
                        `climateData.${state}.solarAnnualProductivity`,
                      ) || ''
                    }
                  ></TextField>
                </Tooltip>
                <Tooltip title="Não atualiza em tempo real">
                  <TextField
                    sx={{
                      paddingLeft: '10px',
                      paddingRight: '60px',
                    }}
                    InputProps={tableCellInputProps}
                    variant="filled"
                    size="small"
                    disabled
                    value={
                      getValues(`climateData.${state}.capacityFactor`) || ''
                    }
                  ></TextField>
                </Tooltip>
              </Container>
            ))}
          </Container>
          <Container
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: 'auto',
              gap: 1,
              paddingTop: '100px',
            }}
          >
            <Container
              sx={{
                gridColumn: '1/2',
                alignSelf: 'center',
              }}
            >
              <Typography sx={tableTextStyles}>FORMAS DE PAGAMENTO</Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '2/3',
                alignSelf: 'center',
              }}
            >
              <Typography sx={tableTextStyles}>CONTROLE DE ACESSO</Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '1/2',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'paymentData.contractSignature',
                  label: 'Assinatura do contrato',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'paymentData.landLeaseContractSignature',
                  label: 'Cessão do contrato de terras',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'paymentData.gridConnectionAllowance',
                  label: 'Obtenção do parecer de acesso',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'paymentData.mobilizationToSite',
                  label: 'Mobilização para o site',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'paymentData.structureDeliveranceOnSite',
                  label: 'Entrega de estrutura no site',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'paymentData.moduleDeliveranceOnSite',
                  label: 'Entrega de módulos no site',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'paymentData.inverterDeliveranceOnSite',
                  label: 'Entrega de inversores no site',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'paymentData.installationsDone',
                  label: 'Finalização das instalações',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'paymentData.powerPlantDeliverance',
                  label: 'Entrega da planta - CAF',
                  min: 0,
                  max: 1,
                  validate: isNotNanValidate,
                  deps: ['paymentData.sum'],
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
              ].map(({ ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                />
              ))}
              <TextField
                label="Total"
                variant="filled"
                {...register('paymentData.sum', {
                  validate: paymentDataValidate({
                    getValues,
                    setPaymentDataSum,
                  }),
                })}
                error={Boolean(errors.paymentData?.sum)}
                helperText={errors.paymentData?.sum?.message}
                sx={formItemStyles}
                disabled
                size="small"
                value={paymentDataSumInputValue || ''}
              />
            </Container>
            <Container
              sx={{
                gridColumn: '2/3',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'blockPowerPlantPurchase',
                  label: 'Bloquear compra de usina remota',
                  ...booleanInputProps,
                  textFieldStyles: {
                    ...formItemStyles,
                    backgroundColor: '#8DC77F',
                    '& .MuiFormHelperText-root.Mui-error': {
                      backgroundColor: 'white',
                    },
                  },
                  formHelperTextProps: {
                    sx: {
                      backgroundColor: 'white',
                      margin: 0,
                    },
                  },
                },
                {
                  name: 'blockPowerPlantRemoteDevelopment',
                  label: 'Bloquear desenvolvimento de usina remota',
                  ...booleanInputProps,
                  textFieldStyles: {
                    ...formItemStyles,
                    backgroundColor: '#E7C672',
                  },
                  formHelperTextProps: {
                    sx: {
                      backgroundColor: 'white',
                      margin: 0,
                    },
                  },
                },
                {
                  name: 'blockPowerPlantLocalDevelopment',
                  label: 'Bloquear desenvolvimento de usina local',
                  ...booleanInputProps,
                  textFieldStyles: {
                    ...formItemStyles,
                    backgroundColor: '#6FB6DE',
                  },
                  formHelperTextProps: {
                    sx: {
                      backgroundColor: 'white',
                      margin: 0,
                    },
                  },
                },
              ].map(({ ...props }) => (
                <ControlledMaskedInput
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                />
              ))}
            </Container>
            <Container
              sx={{
                gridColumn: '1/2',
                alignSelf: 'center',
                paddingTop: '30px',
              }}
            >
              <Typography sx={tableTextStyles}>PROPOSTA</Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '1/2',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                { name: 'proposalWebsite', label: 'Website', max: 50, min: 2 },
                {
                  name: 'proposalInstagram',
                  label: 'Instagram',
                  max: 50,
                  min: 2,
                },
                { name: 'proposalEmail', label: 'Email', max: 50, min: 2 },
                {
                  name: 'proposalSignatureName',
                  label: 'Nome da Assinatura',
                  max: 50,
                  min: 2,
                },
                { name: 'proposalPhone', label: 'Telefone', max: 30, min: 2 },
              ].map(({ ...props }) => (
                <ControlledMaskedInput
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                />
              ))}
            </Container>
          </Container>
          <Container
            sx={{
              display: 'flex',
              width: '100%',
              gap: '50px',
              justifyContent: 'center',
              marginTop: '50px',
              marginBottom: '50px',
            }}
          >
            <Button
              variant="contained"
              color="warning"
              type="submit"
              disabled={isLoading}
              sx={{ width: '200px' }}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              href={config.internalPaths.adminHome}
              disabled={isLoading}
              sx={{ width: '200px' }}
            >
              Cancelar
            </Button>
          </Container>
        </Box>
      </Container>
    </AppContainer>
  );
}
