export const firstTableCellStyles = {
  textOverflow: 'ellipsis',
  width: '400px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const otherTableCellStyles = {
  width: '120px',
};

export const iconStyles = { cursor: 'pointer', color: 'black' };

export const tableRowStyles = {
  height: 73,
};
