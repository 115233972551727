import { getApiInstance } from '../api';

export const listPowerDistributionCompanies = async (token: string) => {
  const api = getApiInstance(token);
  try {
    const result = await api.get('/power-distribution-companies/list');

    return { success: true, data: result.data };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      message: `Erro ao criar proposta. ${(error as Error).message}`,
    };
  }
};
