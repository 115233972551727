import axios from 'axios';
import { getApiInstance } from '../api';

export const changePassword = async ({
  email,
  password,
  newPassword,
}: {
  email: string;
  password: string;
  newPassword: string;
}) => {
  const api = getApiInstance();
  try {
    await api.put('/change-password', {
      email,
      password,
      newPassword,
    });

    return { success: true };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error) && error.response?.status) {
      if (error.response?.status === 400) {
        const apiMessage = error?.response?.data?.message;
        const arrayOfMessages =
          typeof apiMessage === 'string' ? [apiMessage] : apiMessage || [];
        const message = ['Erro ao trocar senha.', ...arrayOfMessages].join(' ');
        return {
          success: false,
          message,
        };
      }
      if (error.response?.status === 401) {
        return {
          success: false,
          message: 'Troca de senha não autorizada. Credenciais inválidas.',
        };
      }
      return {
        success: false,
        message: `Erro ao trocar senha. Erro no servidor: ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao trocar senha. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
