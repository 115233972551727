import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { config } from '../../common/config/base';
import {
  LoginPage,
  AdminHome,
  IntegratorForm,
  IntegratorProposalList,
} from '../../pages';
import useAuth from '../../common/hooks/auth';
import { Role } from '../../common/enums';
import Loading from '../loading';
import { ChangePasswordPage } from '../../pages/change-password';
import { ProposalForm } from '../../pages/proposal/form';
import { PowerPlantForm } from '../../pages/power-plant';
import { PowerDistributionCompaniesPage } from '../../pages/power-distribution-companies';
import { AdminSettingsForm } from '../../pages/admin-settings';
import PdfRenderer from '../../pages/proposal/render-pdf-templates/index';

function PrivateRouting() {
  const {
    user,
    isCheckingAuthState,
    shouldRedirectToChangePassword,
    clearRedirectToChangePassword,
  } = useAuth();

  if (shouldRedirectToChangePassword) {
    clearRedirectToChangePassword();
    return (
      <Routes>
        <Route
          path="*"
          element={
            <Navigate to={config.internalPaths.changePassword}></Navigate>
          }
        />
      </Routes>
    );
  }

  if (isCheckingAuthState) {
    return <Loading isLoading={true}></Loading>;
  }

  const isLoggedIn = !!user;
  const isAdmin = isLoggedIn && user.role === Role.ADMIN;
  const isUser = isLoggedIn && user.role === Role.USER;

  if (isAdmin) {
    return (
      <Routes>
        <Route
          path={config.internalPaths.adminHome}
          element={<AdminHome></AdminHome>}
        />
        <Route
          path={config.internalPaths.integratorProposal}
          element={<IntegratorProposalList></IntegratorProposalList>}
        />
        <Route
          path={config.internalPaths.editProposal}
          element={<ProposalForm></ProposalForm>}
        />
        <Route
          path={config.internalPaths.proposalPdf}
          element={<PdfRenderer></PdfRenderer>}
        />
        <Route
          path={config.internalPaths.createIntegrator}
          element={<IntegratorForm></IntegratorForm>}
        />
        <Route
          path={config.internalPaths.editIntegrator}
          element={<IntegratorForm></IntegratorForm>}
        />
        <Route
          path={config.internalPaths.createPowerPlant}
          element={<PowerPlantForm></PowerPlantForm>}
        />
        <Route
          path={config.internalPaths.editPowerPlant}
          element={<PowerPlantForm></PowerPlantForm>}
        />
        <Route
          path={config.internalPaths.powerDistributionCompanies}
          element={
            <PowerDistributionCompaniesPage></PowerDistributionCompaniesPage>
          }
        />
        <Route
          path={config.internalPaths.adminSettings}
          element={<AdminSettingsForm></AdminSettingsForm>}
        />
        <Route
          path="*"
          element={<Navigate to={config.internalPaths.adminHome}></Navigate>}
        />
      </Routes>
    );
  }

  if (isUser) {
    return (
      <Routes>
        <Route
          path={config.internalPaths.proposal}
          element={<IntegratorProposalList></IntegratorProposalList>}
        />
        <Route
          path={config.internalPaths.createProposal}
          element={<ProposalForm></ProposalForm>}
        />
        <Route
          path={config.internalPaths.editProposal}
          element={<ProposalForm></ProposalForm>}
        />
        <Route
          path={config.internalPaths.proposalPdf}
          element={<PdfRenderer></PdfRenderer>}
        />
        <Route
          path={config.internalPaths.userProfile}
          element={<IntegratorForm></IntegratorForm>}
        />
        <Route
          path="*"
          element={<Navigate to={config.internalPaths.proposal}></Navigate>}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path={config.internalPaths.login}
        element={<LoginPage></LoginPage>}
      />
      <Route
        path={config.internalPaths.changePassword}
        element={<ChangePasswordPage></ChangePasswordPage>}
      />
      <Route
        path="*"
        element={<Navigate to={config.internalPaths.login}></Navigate>}
      />
    </Routes>
  );
}

export default PrivateRouting;
