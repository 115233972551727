import { Button, useTheme } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { useCallback } from 'react';
import { ProposalType } from '../../../common/enums';

export function SelectProposalType({
  setProposalType,
}: {
  setProposalType: React.Dispatch<
    React.SetStateAction<ProposalType | undefined>
  >;
}) {
  const theme = useTheme();

  const selectProposalTypeStyles = useCallback(
    (backgroundColor: string) => ({
      height: '130px',
      width: '230px',
      backgroundColor,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      fontSize: '1em',
      fontWeight: 600,
      textTransform: 'uppercase',
      ':hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    }),
    [theme],
  );

  const handleButtonClick = useCallback(
    (proposalType: ProposalType) => () => {
      return setProposalType(proposalType);
    },
    [setProposalType],
  );

  return (
    <Container
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      <Button
        sx={selectProposalTypeStyles('#82B675')}
        onClick={handleButtonClick(ProposalType.PURCHASE)}
      >
        Compra de Usina Remota
      </Button>
      <Button
        sx={selectProposalTypeStyles('#E7C672')}
        onClick={handleButtonClick(ProposalType.REMOTE_DEVELOPMENT)}
      >
        Desenvolvimento de Usina Remota
      </Button>
      <Button
        sx={selectProposalTypeStyles('#6FB6DE')}
        onClick={handleButtonClick(ProposalType.LOCAL_DEVELOPMENT)}
      >
        Orçamento de Usina Local na Unidade Consumidora
      </Button>
    </Container>
  );
}
