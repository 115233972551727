import { getApiInstance } from '../api';

export const verifyToken = async (token: string) => {
  const api = getApiInstance(token);
  try {
    await api.get('/verify-token');
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};
