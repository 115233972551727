import React, { createRef } from 'react';
import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import { FlashOn, Construction, LightMode } from '@mui/icons-material';
import { Header } from '../../components/header';
import bannerLading from '../../images/banner-landing.gif';
import nunoMarques from '../../images/nuno-marques.jpg';
import energyPlant from '../../images/energy-plant.jpg';
import logoFooter from '../../images/logo-footer.png';
import { Footer } from '../../components/footer';
import theme from '../../theme/default';
import { ContactForm } from './form';

export function LandingPage() {
  const refAbout: any = createRef();
  const refContact: any = createRef();
  const refServices: any = createRef();

  const handleScroll = (ref: any) => {
    return ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  return (
    <>
      <Header
        refAbout={refAbout}
        refContact={refContact}
        refServices={refServices}
        handleScroll={handleScroll}
      ></Header>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <Box
          sx={{
            background: `linear-gradient(0deg, rgba(40, 40, 40, 0.8), rgba(40, 40, 40, 0.8)), url(${bannerLading})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            padding: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: 'center',
              fontSize: '2.3em',
              fontWeight: 700,
              lineHeight: '50px',
              color: theme.palette.secondary.main,
            }}
          >
            Conectando{' '}
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1.0em',
                fontWeight: 700,
                lineHeight: '50px',
                color: theme.palette.warning.main,
              }}
              component="span"
            >
              energia
            </Typography>{' '}
            pelo Brasil.
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.warning.main,
            padding: '200px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              backgroundColor: theme.palette.secondary.main,
              boxShadow: '0px 4px 10px 2px rgba(40, 40, 40, 0.15)',
              padding: '20px',
              width: '800px',
              height: '400px',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              top: 400,
              right: 0,
              left: 0,
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              ref={refAbout}
              sx={{
                height: '100%',
                width: '100%',
                background: `url(${energyPlant})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
                boxSizing: 'border-box',
              }}
            />
            <Box
              sx={{
                width: '70%',
                flexDirection: 'row',
                paddingLeft: '100px',
                justifyContent: 'center',

                textAlign: 'left',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '25px',
                  paddingBottom: '25px',
                }}
              >
                Gerar Livre, conectando energia pelo Brasil
              </Typography>
              <Typography
                paragraph={true}
                sx={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '20px',
                }}
              >
                Solução de desenvolvimento e implantação de usina solar no
                Mercado Livre de Energia para grandes empresas e unidades
                conectadas em média tensão.
              </Typography>
              <Typography
                paragraph={true}
                sx={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '20px',
                }}
              >
                Surpreenda-se com o resultado!
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          ref={refServices}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            background: theme.palette.primary.contrastText,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              padding: '100px',
              textAlign: 'center',
              fontSize: '2.5em',
              fontWeight: 700,
              lineHeight: '45.36px',
              color: theme.palette.primary.main,
            }}
          >
            Conheça os nossos{' '}
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1.0em',
                fontWeight: 700,
                lineHeight: '50px',
                color: theme.palette.warning.main,
              }}
              component="span"
            >
              serviços
            </Typography>
          </Typography>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              textAlign: 'center',
              padding: '30px 150px 80px 150px',
            }}
          >
            <Grid item xs={3}>
              <FlashOn
                sx={{
                  fontSize: 80,
                }}
              />
              <Typography
                sx={{
                  padding: '15px',
                  fontSize: '1.5em',
                  fontWeight: 600,
                  lineHeight: '45.36px',
                }}
              >
                Usinas Aprovadas
              </Typography>
              <Typography
                sx={{
                  padding: '5px',
                  fontSize: '1.0em',
                  fontWeight: 400,
                  lineHeight: '25px',
                }}
              >
                Adquira uma de nossas usinas em desenvolvimento, já aprovadas
                pelos órgãos responsáveis e prontas para construir.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Construction
                sx={{
                  fontSize: 80,
                }}
              />
              <Typography
                sx={{
                  padding: '15px',
                  fontSize: '1.5em',
                  fontWeight: 600,
                  lineHeight: '45.36px',
                }}
              >
                Desenvolvimento de Usina
              </Typography>
              <Typography
                sx={{
                  padding: '5px',
                  fontSize: '1.0em',
                  fontWeight: 400,
                  lineHeight: '25px',
                }}
              >
                Desenvolvimento de usina solar remota ou local sob medida para a
                sua necessidade.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <LightMode
                sx={{
                  fontSize: 80,
                }}
              />
              <Typography
                sx={{
                  padding: '15px',
                  fontSize: '1.5em',
                  fontWeight: 600,
                  lineHeight: '45.36px',
                }}
              >
                Plataforma
              </Typography>
              <Typography
                sx={{
                  padding: '5px',
                  fontSize: '1.0em',
                  fontWeight: 400,
                  lineHeight: '25px',
                }}
              >
                Plataforma Gerar Livre completa, integrando soluções e gerando
                propostas para grandes clientes.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            background: `linear-gradient(0deg, rgba(100, 100, 100, 0.7), rgba(100, 100, 100, 0.7)), url(${nunoMarques})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            padding: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            ref={refContact}
            sx={{
              height: '550px',
              width: '1000px',
              boxShadow: '0px 4px 10px 2px rgba(40, 40, 40, 0.15)',
              display: 'flex',
              background: theme.palette.primary.contrastText,
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: '150px 0px 0px 50px',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.5em',
                    fontWeight: 700,
                    lineHeight: '60px',
                    paddingBottom: '30px',
                    color: theme.palette.primary.main,
                  }}
                >
                  Fale conosco
                </Typography>
                <Divider
                  variant="inset"
                  sx={{
                    textAlign: 'left',
                    width: '40%',
                    margin: '0 auto 0 0',
                    background: theme.palette.warning.main,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '1.0em',
                    fontWeight: 500,
                    paddingTop: '30px',
                    color: theme.palette.primary.main,
                  }}
                >
                  Quer utilizar a plataforma Gerar Livre? Possui oportunidade de
                  negócio ou interesse em adquirir sua usina solar? Entre em
                  contato!
                </Typography>
              </Box>
              <ContactForm></ContactForm>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: '200px',
            padding: '20px',
            background: theme.palette.secondary.dark,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              width: 260,
              height: 30,
              textAlign: 'center',
              backgroundSize: '100%',
              justifyContent: 'center',
              background: `url(${logoFooter})`,
              backgroundRepeat: 'no-repeat',
              paddingBottom: '40px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Link
              onClick={() => handleScroll(refAbout)}
              sx={{
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 500,
                lineHeight: '50px',
              }}
            >
              SOBRE
            </Link>
            <Typography
              sx={{
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 500,
                lineHeight: '50px',
                padding: '0px 10px 0px 10px',
              }}
            >
              |
            </Typography>
            <Link
              onClick={() => handleScroll(refServices)}
              sx={{
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 500,
                lineHeight: '50px',
              }}
            >
              SERVIÇOS
            </Link>
            <Typography
              sx={{
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 500,
                lineHeight: '50px',
                padding: '0px 10px 0px 10px',
              }}
            >
              |
            </Typography>
            <Link
              onClick={() => handleScroll(refContact)}
              sx={{
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 500,
                lineHeight: '50px',
              }}
            >
              CONTATO
            </Link>
            <Typography
              sx={{
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 500,
                lineHeight: '50px',
                padding: '0px 10px 0px 10px',
              }}
            >
              |
            </Typography>
            <Link
              href="/login"
              sx={{
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.5em',
                fontWeight: 500,
                lineHeight: '50px',
              }}
            >
              ENTRAR
            </Link>
          </Box>
        </Box>
      </Box>
      <Footer
        color={theme.palette.primary.dark}
        backgroundColor={theme.palette.primary.dark}
      ></Footer>
    </>
  );
}
