import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ReactElement } from 'react';
import { currencyFormatterNoDecimals } from './edit-pdf/common/currency-formatter';

const headerCellProps = {
  align: 'center',
  sx: {
    color: 'white',
    fontSize: '24px',
  },
} as TableCellProps;

const bodyCellProps = {
  align: 'center',
  sx: {
    fontSize: '24px',
  },
} as TableCellProps;

const DenseTable = ({ proposalData }: { proposalData: any }) => {
  const { grossIncome, expenses } = proposalData?.cashFlow?.elements || {};
  const { capexCosts } = proposalData?.project || {};
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead
          sx={{
            backgroundColor: '#002060',
            color: 'white',
          }}
        >
          <TableRow
            sx={{
              '& td, & th': {
                border: 0,
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              },
            }}
          >
            <TableCell {...headerCellProps}>Ano</TableCell>
            <TableCell {...headerCellProps} colSpan={3}>
              Investimento
            </TableCell>
            <TableCell {...headerCellProps}>Economia Bruta</TableCell>
            <TableCell {...headerCellProps} colSpan={2}>
              Despesas Estimadas
            </TableCell>
            <TableCell {...headerCellProps}>
              Economia Estimada Líquida
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              '& td, & th': {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              },
            }}
          >
            <TableCell {...headerCellProps}></TableCell>
            <TableCell {...headerCellProps}>Usina Solar</TableCell>
            <TableCell {...headerCellProps}>
              Rede Elétrica e SMF (estimado)
            </TableCell>
            <TableCell {...headerCellProps}>Total</TableCell>
            <TableCell {...headerCellProps}></TableCell>
            <TableCell {...headerCellProps}>Gestão</TableCell>
            <TableCell {...headerCellProps}>Outras</TableCell>
            <TableCell {...headerCellProps}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell {...bodyCellProps}>{0}</TableCell>
            <TableCell {...bodyCellProps}>
              {currencyFormatterNoDecimals.format(
                capexCosts?.total - capexCosts?.grid.total,
              )}
            </TableCell>
            <TableCell {...bodyCellProps}>
              {currencyFormatterNoDecimals.format(capexCosts?.grid.total)}
            </TableCell>
            <TableCell {...bodyCellProps}>
              {currencyFormatterNoDecimals.format(capexCosts?.total)}
            </TableCell>
            <TableCell {...bodyCellProps}></TableCell>
            <TableCell {...bodyCellProps}></TableCell>
            <TableCell {...bodyCellProps}></TableCell>
            <TableCell {...bodyCellProps}></TableCell>
          </TableRow>
          {new Array(15).fill(0).map((_, i) => {
            const index = i + 1;
            return (
              <TableRow
                key={`${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell {...bodyCellProps}>{index}</TableCell>
                <TableCell {...bodyCellProps}></TableCell>
                <TableCell {...bodyCellProps}></TableCell>
                <TableCell {...bodyCellProps}></TableCell>
                <TableCell {...bodyCellProps}>
                  {currencyFormatterNoDecimals.format(grossIncome[index])}
                </TableCell>
                <TableCell {...bodyCellProps}>
                  -
                  {currencyFormatterNoDecimals.format(
                    expenses.management[index],
                  )}
                </TableCell>
                <TableCell {...bodyCellProps}>
                  -
                  {currencyFormatterNoDecimals.format(
                    expenses.total[index] - expenses.management[index],
                  )}
                </TableCell>
                <TableCell {...bodyCellProps}>
                  {currencyFormatterNoDecimals.format(
                    grossIncome[index] - expenses.total[index],
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const EconomyTable = ({
  innerRef,
  proposalData,
}: {
  proposalData: any;
  innerRef: any;
}): ReactElement => {
  if (!proposalData) return <div></div>;
  return (
    <div ref={innerRef}>
      <DenseTable proposalData={proposalData}></DenseTable>
    </div>
  );
};
