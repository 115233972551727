import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ReactElement } from 'react';
import { formatNumber } from './edit-pdf/common/general-number-formatter';
import {
  currencyFormatterNoDecimals,
  currencyFormatterWithDecimals,
} from './edit-pdf/common/currency-formatter';
import { ProposalType } from '../../../common/enums';

const headerCellProps = {
  align: 'center',
  sx: {
    color: 'white',
    fontSize: '24px',
  },
} as TableCellProps;

const bodyCellProps = {
  align: 'center',
  sx: {
    fontSize: '24px',
  },
} as TableCellProps;

const DenseTable = ({ proposalData }: { proposalData: any }) => {
  const projectCost =
    proposalData?.project?.capexCosts?.total -
    proposalData?.project?.capexCosts?.grid?.total;
  const data = [
    [
      '1',
      proposalData?.customer?.name,
      formatNumber(proposalData?.powerPlant?.peakPowerInMWp * 1000, 0),
      proposalData?.proposalType === ProposalType.LOCAL_DEVELOPMENT
        ? 'Fixa'
        : 'Tracker',
      formatNumber(proposalData?.adminSettings?.dollarExchangeRate, 2),
      currencyFormatterNoDecimals.format(projectCost),
      currencyFormatterWithDecimals.format(
        projectCost / (proposalData?.powerPlant?.peakPowerInMWp * 1_000_000),
      ),
    ],
    [
      'Total',
      '',
      formatNumber(proposalData?.powerPlant?.peakPowerInMWp * 1000, 0),
      '',
      '',
      currencyFormatterNoDecimals.format(projectCost),
      currencyFormatterWithDecimals.format(
        projectCost / (proposalData?.powerPlant?.peakPowerInMWp * 1_000_000),
      ),
    ],
  ];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead
          sx={{
            backgroundColor: '#002060',
            color: 'white',
          }}
        >
          <TableRow
            sx={{
              '& td, & th': {
                border: 0,
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              },
            }}
          >
            <TableCell {...headerCellProps} colSpan={4}>
              Especificações
            </TableCell>
            <TableCell {...headerCellProps}>Câmbio</TableCell>
            <TableCell {...headerCellProps} colSpan={2}>
              CAPEX
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              '& td, & th': {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              },
            }}
          >
            <TableCell {...headerCellProps}>Projeto</TableCell>
            <TableCell {...headerCellProps}>Descrição</TableCell>
            <TableCell {...headerCellProps}>Potência DC [KWp]</TableCell>
            <TableCell {...headerCellProps}>Estrutura</TableCell>
            <TableCell {...headerCellProps}>R$/USD</TableCell>
            <TableCell {...headerCellProps}>Total (R$)</TableCell>
            <TableCell {...headerCellProps}>R$/Wp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              key={`${i}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row.map((value, j) => (
                <TableCell key={`${i}-${j}`} {...bodyCellProps}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const FullEpcTable = ({
  innerRef,
  proposalData,
}: {
  proposalData: any;
  innerRef: any;
}): ReactElement => {
  if (!proposalData) return <div></div>;
  return (
    <div ref={innerRef}>
      <DenseTable proposalData={proposalData}></DenseTable>
    </div>
  );
};
