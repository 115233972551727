import React from 'react';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { config } from '../../common/config/base';
import useAuth from '../../common/hooks/auth';

const buttonStyle = {
  width: '100px',
  height: '40px',
  marginLeft: '50px',
};

export function HeaderButtons() {
  const { user, handleLogout } = useAuth();
  const { pathname } = useLocation();

  const isLandingPage = pathname === config.internalPaths.landing;
  const isChangePasswordPage = pathname === config.internalPaths.changePassword;
  const isLoggedIn = !!user;

  if (isLandingPage || isChangePasswordPage) {
    return (
      <Button
        variant="contained"
        color="warning"
        href={config.internalPaths.login}
        sx={buttonStyle}
      >
        Entrar
      </Button>
    );
  }

  if (isLoggedIn) {
    return (
      <Button
        variant="contained"
        color="warning"
        onClick={handleLogout}
        sx={buttonStyle}
      >
        Sair
      </Button>
    );
  }

  return <></>;
}
