import React, { useEffect, useState } from 'react';
import { TextField, Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';
import { formItemStyles, menuStyles } from '../../common/styles/form-styles';

export function ControlledMaskedInput({
  name,
  isRequired,
  isReadOnly,
  min,
  max,
  minLength,
  maxLength,
  validate,
  pattern,
  deps,
  label,
  control,
  errors,
  transform,
  children,
  isSelect,
  tooltip,
  type,
  shouldShrinkLabel,
  textFieldStyles,
  inputProps,
  inputLabelProps,
  formHelperTextProps,
}: //@TODO fix any -> see attempt at stashed file on jan 5th 2022
any) {
  const [inputValue, setInputValue] = useState<string>('');
  const [isDirty, setIsDirty] = useState(false);
  const [valueWasLoaded, setValueWasLoaded] = useState(false);

  const returnValue = (value: any) => value;
  const formValueToInputValue = transform?.formValueToInputValue ?? returnValue;
  const inputValueToFormValue = transform?.inputValueToFormValue ?? returnValue;
  const mask = transform?.mask ?? returnValue;
  const unmask = transform?.unmask ?? returnValue;

  const errorsValue = name
    .split('.')
    .reduce((lastValue: any, currentPath: any) => {
      return lastValue?.[currentPath];
    }, errors);

  useEffect(() => {
    if (inputValue === '' && isDirty === false) {
      return;
    }

    if (valueWasLoaded === false && isDirty === false) {
      return setValueWasLoaded(true);
    }

    setIsDirty(true);
  }, [inputValue, isDirty, valueWasLoaded]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? 'Campo obrigatório' : undefined,
        minLength:
          typeof minLength === 'number'
            ? {
                value: minLength,
                message: `Deve ter no mínimo ${minLength} caracteres`,
              }
            : undefined,
        maxLength:
          typeof maxLength === 'number'
            ? {
                value: maxLength,
                message: `Deve ter no máximo ${maxLength} caracteres`,
              }
            : undefined,
        min:
          typeof min === 'number'
            ? {
                value: min,
                message: `Deve ser no mínimo ${formValueToInputValue(min)}`,
              }
            : undefined,
        max:
          typeof max === 'number'
            ? {
                value: max,
                message: `Deve ser no máximo ${formValueToInputValue(max)}`,
              }
            : undefined,
        pattern:
          typeof pattern === 'object'
            ? {
                value: pattern.value,
                message: pattern.message,
              }
            : undefined,
        validate: validate ? { ...validate } : undefined,
        deps,
      }}
      render={({ field }) => {
        const setValue = () => {
          const baseValue = isDirty
            ? inputValue
            : formValueToInputValue(field.value);
          const maskedValue = mask(baseValue);
          const value =
            isSelect && children?.length === 0
              ? ''
              : typeof maskedValue === 'undefined' || Number.isNaN(maskedValue)
              ? ''
              : maskedValue;
          return value;
        };

        const onChange = (event: any) => {
          const { value } = event?.target;
          const maskedValue = mask(value);
          const unmaskedValue = unmask(maskedValue);
          setInputValue(maskedValue);
          field.onChange(inputValueToFormValue(unmaskedValue));
        };

        return (
          <Tooltip title={tooltip?.title} placement={tooltip?.placement}>
            <TextField
              {...field}
              select={isSelect}
              value={setValue()}
              onChange={onChange}
              label={label}
              type={type}
              defaultValue={isSelect ? '' : undefined}
              variant="filled"
              size="small"
              error={Boolean(errorsValue)}
              helperText={errorsValue?.message}
              SelectProps={{ MenuProps: { sx: menuStyles } }}
              sx={textFieldStyles || formItemStyles}
              InputProps={{ readOnly: isReadOnly, ...(inputProps || {}) }}
              InputLabelProps={{
                shrink: shouldShrinkLabel,
                ...(inputLabelProps || {}),
              }}
              FormHelperTextProps={formHelperTextProps}
            >
              {children}
            </TextField>
          </Tooltip>
        );
      }}
    />
  );
}
