import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

function ConfirmationDialog({
  isVisible,
  title,
  message,
  handleConfirm,
  handleReject,
}: {
  isVisible: boolean;
  title: string;
  message: string;
  handleConfirm: () => Promise<void> | void;
  handleReject: () => Promise<void> | void;
}) {
  return (
    <>
      {isVisible && (
        <Dialog open={isVisible} onClose={handleReject}>
          <DialogContent>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
          </DialogContent>
          <DialogActions
            sx={{
              padding: '20px',
            }}
          >
            <Button onClick={handleConfirm} variant="contained">
              Confirmar
            </Button>
            <Button onClick={handleReject} autoFocus>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default ConfirmationDialog;
