import React from 'react';
import { AppBar, Box, Toolbar, useTheme, Container } from '@mui/material';
import { Logo } from '../logo';
import { Link as RouterLink } from 'react-router-dom';
import { config } from '../../common/config/base';
import { HeaderLinks } from './links';
import { HeaderButtons } from './buttons';

export function Header({
  refAbout,
  refContact,
  refServices,
  handleScroll,
}: any) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: 70,
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          height: 70,
          paddingLeft: { lg: '210px', md: '70px', sm: '0px' },
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <RouterLink to={config.internalPaths.landing}>
            <Logo></Logo>
          </RouterLink>
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <HeaderLinks
              refAbout={refAbout}
              refContact={refContact}
              refServices={refServices}
              handleScroll={handleScroll}
            ></HeaderLinks>
            <HeaderButtons />
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
