import { getApiInstance } from '../api';

export const getProposalDefaultParams = async (token: string) => {
  const api = getApiInstance(token);
  try {
    const result = await api.get(`/proposals/default-parameters`);

    return { success: true, data: result.data };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `Erro ao carregar parâmetros iniciais. ${
        (error as Error).message
      }`,
    };
  }
};
