import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import emailValidator from 'email-validator';
import theme from '../../theme/default';
import { sendContactEmail } from '../../common/api/contact/send-contact-email';
import useGeneralAppState from '../../common/hooks/general-app-state';
import Loading from '../../components/loading/index';

type Inputs = {
  name: string;
  phone: string;
  email: string;
  document: string;
  message: string;
};

export function ContactForm() {
  const [isLoading, setIsLoading] = useState(false);
  const { setAppMessage } = useGeneralAppState();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsLoading(true);
    const { success, message } = await sendContactEmail(data);
    setIsLoading(false);
    if (success) {
      setAppMessage({
        title: 'Sucesso',
        content:
          'Sua mensagem foi enviada para nossa equipe. Entraremos em contato em breve.',
      });
      reset();
      return;
    }

    setAppMessage({
      title: 'Erro',
      content: message as string,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'space-evenly',
        }}
      >
        <TextField
          sx={{
            marginBottom: '10px',
            width: '300px',
          }}
          label="Nome"
          variant="filled"
          size="small"
          {...register('name', {
            required: 'Campo obrigatório',
          })}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
        ></TextField>
        <TextField
          sx={{
            marginBottom: '10px',
            width: '300px',
          }}
          label="Telefone"
          variant="filled"
          size="small"
          {...register('phone', {
            required: 'Campo obrigatório',
          })}
          error={Boolean(errors.phone)}
          helperText={errors.phone?.message}
        ></TextField>
        <TextField
          sx={{
            marginBottom: '10px',
            width: '300px',
          }}
          label="E-mail"
          variant="filled"
          size="small"
          {...register('email', {
            required: 'Campo obrigatório',
            validate: {
              isValidEmail: (email) =>
                emailValidator.validate(email) || 'Insira um email válido',
            },
          })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        ></TextField>
        <TextField
          sx={{
            marginBottom: '10px',
            width: '300px',
          }}
          label="CNPJ"
          variant="filled"
          size="small"
          {...register('document', {
            required: 'Campo obrigatório',
          })}
          error={Boolean(errors.document)}
          helperText={errors.document?.message}
        ></TextField>
        <TextField
          sx={{
            marginBottom: '10px',
            width: '300px',
          }}
          label="Mensagem"
          variant="filled"
          multiline
          rows={4}
          {...register('message', {
            required: 'Campo obrigatório',
          })}
          error={Boolean(errors.message)}
          helperText={errors.message?.message}
        ></TextField>
        <Button
          sx={{
            marginTop: '20px',
            width: '150px',
            background: theme.palette.secondary.light,
            ':hover': {
              background: theme.palette.secondary.dark,
            },
          }}
          variant="contained"
          color="success"
          type="submit"
        >
          Enviar
        </Button>
      </Box>
      <Loading isLoading={isLoading}></Loading>
    </Box>
  );
}
