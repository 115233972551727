import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import defaultTheme from './theme/default';
import AuthProvider from './common/contexts/auth';
import GeneralAppStateProvider from './common/contexts/general-app-state';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <GeneralAppStateProvider>
      <AuthProvider>
        <ThemeProvider theme={defaultTheme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </GeneralAppStateProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
