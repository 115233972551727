import { getApiInstance } from '../api';

export const listPowerPlants = async ({
  token,
  page,
}: {
  token: string;
  page?: number;
}) => {
  const api = getApiInstance(token);
  try {
    const searchParams = new URLSearchParams({
      page: typeof page === 'number' ? String(page) : '',
    });

    const { data } = await api.get(`/power-plants?${searchParams.toString()}`);

    return { success: true, data: data.data, count: data.count };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      message: `Erro ao carregar usinas. ${(error as Error).message}`,
    };
  }
};
