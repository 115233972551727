import { getApiInstance } from '../api';

export const getAccessPermissions = async (token: string) => {
  const api = getApiInstance(token);
  try {
    const result = await api.get('/admin-settings/access-permissions');

    return { success: true, data: result.data };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      message: `Erro ao carregar permissões de acesso. ${
        (error as Error).message
      }`,
    };
  }
};
