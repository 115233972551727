import axios from 'axios';
import { getApiInstance } from '../api';

export const toggleContractSignature = async ({
  token,
  proposalId,
}: {
  token: string;
  proposalId: string;
}) => {
  const api = getApiInstance(token);
  try {
    const { data } = await api.put(
      `/proposals/${proposalId}/contract/signature`,
    );

    return { success: true, data };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error) && error.response?.status) {
      return {
        success: false,
        message: `Erro ao atualizar assinatura da proposta. ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao atualizar assinatura da proposta. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
