import React from 'react';
import { createTheme } from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { ptBR } from '@mui/x-data-grid';

//https://mui.com/material-ui/guides/routing/#global-theme-link
const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#082944',
        contrastText: '#E8E8E8',
        dark: '#282828',
        light: '#BABABA',
      },
      secondary: {
        main: '#E8E8E8',
        dark: '#3E3E3E',
        light: '#00A6B4',
      },
      warning: {
        main: '#FF8B21',
        contrastText: '#E8E8E8',
      },
    },
    typography: {
      fontFamily: ['Outfit'].join(','),
      button: {
        fontWeight: 700,
        fontSize: '1em',
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  },
  ptBR,
);

export default theme;
