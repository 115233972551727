import React from 'react';
import { Container } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import { config } from '../../common/config/base';
import { IntegratorTable } from './integrator-table';
import { AppContainer } from '../../components/app-container';
import { PowerPlantTable } from './power-plant-table/index';

export function AdminHome() {
  const theme = useTheme();

  return (
    <AppContainer isLoading={false} title="HOME">
      <Container
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: '0',
        }}
        maxWidth={false}
      >
        <Container>
          <IntegratorTable></IntegratorTable>
          <Container sx={{ paddingTop: '25px', paddingBottom: '50px' }}>
            <Button
              variant="contained"
              sx={{
                width: '300px',
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.0em',
                fontWeight: 800,
              }}
              href={config.internalPaths.createIntegrator}
            >
              Criar Integrador
            </Button>
          </Container>
        </Container>
        <Container>
          <PowerPlantTable></PowerPlantTable>
          <Container sx={{ paddingTop: '25px', paddingBottom: '50px' }}>
            <Button
              variant="contained"
              sx={{
                width: '300px',
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.secondary.main,
                textAlign: 'center',
                fontSize: '1.0em',
                fontWeight: 800,
              }}
              href={config.internalPaths.createPowerPlant}
            >
              Criar Usina
            </Button>
          </Container>
        </Container>
      </Container>
    </AppContainer>
  );
}
