import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export interface IAppMessage {
  title: string;
  content: string;
  url?: string;
}

export const GeneralAppStateContext = createContext({
  appMessage: undefined as IAppMessage | undefined,
  isLoading: false,
  setIsLoading: (state: boolean) => {},
  setAppMessage: (message: IAppMessage | undefined) => {},
});

function GeneralAppStateProvider({ children }: React.PropsWithChildren) {
  const [appMessage, setAppMessage] = useState<IAppMessage | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <GeneralAppStateContext.Provider
      value={{
        appMessage,
        setAppMessage,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </GeneralAppStateContext.Provider>
  );
}

GeneralAppStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GeneralAppStateProvider;
