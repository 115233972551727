import { getApiInstance } from '../api';

export const deletePowerPlant = async ({
  token,
  powerPlantId,
}: {
  token: string;
  powerPlantId: string;
}) => {
  const api = getApiInstance(token);
  try {
    await api.delete(`/power-plants/${powerPlantId}`);

    return { success: true };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `Erro ao deletar usina. ${(error as Error).message}`,
    };
  }
};
