import axios from 'axios';
import { UpdateIntegratorInputs } from '../../../pages/integrator';
import { getApiInstance } from '../api';

export const updateIntegrator = async ({
  token,
  integratorId,
  userInput,
}: {
  token: string;
  integratorId: string;
  userInput: UpdateIntegratorInputs;
}) => {
  const api = getApiInstance(token);
  try {
    await api.put(`/integrators/${integratorId}`, userInput);

    return { success: true };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error) && error.response?.status) {
      if (error.response?.status === 400) {
        const apiMessage = error?.response?.data?.message;
        const arrayOfMessages =
          typeof apiMessage === 'string' ? [apiMessage] : apiMessage || [];
        const message = [
          'Erro ao atualizar integrador.',
          ...arrayOfMessages,
        ].join(' ');
        return {
          success: false,
          message,
        };
      }
      return {
        success: false,
        message: `Erro ao atualizar integrador. ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao atualizar integrador. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
