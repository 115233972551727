import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@mui/system';
import { Box, Button, MenuItem, Typography } from '@mui/material';

import { SubmitHandler, useForm } from 'react-hook-form';

import { config } from '../../common/config/base';
import { ControlledMaskedInput } from '../../components/controlled-masked-input/index';
import {
  formItemStyles,
  sectionSubtitleStyles,
} from '../../common/styles/form-styles';
import {
  formValueToInputValue,
  inputValueToFormValue,
} from '../../common/helpers/input-value-to-form-value';
import { maskNumber, unmaskNumber } from '../../common/helpers/number-mask';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../common/hooks/auth';
import useGeneralAppState from '../../common/hooks/general-app-state';
import { AppContainer } from '../../components/app-container/index';
import { listPowerDistributionCompanies } from '../../common/api/power-distribution-companies/list-pdcs';
import { createPowerPlant } from '../../common/api/power-plants/create-power-plant';
import { updatePowerPlant } from '../../common/api/power-plants/update-power-plant';
import { getPowerPlantById } from '../../common/api/power-plants/get-power-plant-by-id';
import { getBase64 } from '../../common/helpers/convert-file-to-base64';

// @TODO: move interfaces to proper file
interface ListItem {
  name: string;
  id: string;
}

export interface CreatePowerPlantInputs {
  name: string;
  location: string;
  powerDistributionCompany: string;
  operationStartDate: string;
  capacityFactor: number;
  incentivizedEnergy: number;
  sellingPricePerMWp: number;
  projectPricePerMWp: number;
  gridPricePerMWp: number;
  landPurchasePrice: number;
  monthlyLandLeasePerHa: number;
  landAreaInHa: number;
  peakPowerInMWp: number;
  image?: FileList | string;
}

//@TODO: Separate sections into different components
export function PowerPlantForm() {
  const { powerPlantId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setAppMessage } = useGeneralAppState();

  const [isLoading, setIsLoading] = useState(false);
  const [powerDistributionCompanies, setPowerDistributionCompanies] = useState(
    [],
  );

  const isCreatePowerPlantForm = !powerPlantId;
  const pageTitle = isCreatePowerPlantForm ? 'criar usina' : 'editar usina';

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<CreatePowerPlantInputs>({
    defaultValues: async () => {
      if (isCreatePowerPlantForm) {
        return {} as CreatePowerPlantInputs;
      }

      setIsLoading(true);
      const { success, message, data } = await getPowerPlantById({
        token: user?.token as string,
        powerPlantId,
      });
      setIsLoading(false);

      if (success) {
        return data as CreatePowerPlantInputs;
      }

      setAppMessage({
        title: 'Erro',
        content: message as string,
      });
      return {} as CreatePowerPlantInputs;
    },
  });

  const fetchPowerDistributionCompanies = useCallback(async () => {
    const { success, message, data } = await listPowerDistributionCompanies(
      user?.token as string,
    );

    if (success) {
      setPowerDistributionCompanies(data);
      return;
    }

    setAppMessage({ title: 'Error', content: message as string });
    navigate(config.internalPaths.adminHome);
  }, [user, setAppMessage, navigate]);

  useEffect(() => {
    if (!user) {
      navigate(config.internalPaths.adminHome);
      return;
    }

    fetchPowerDistributionCompanies();
  }, [navigate, user, fetchPowerDistributionCompanies]);

  const handleCreate = useCallback(
    async (data: CreatePowerPlantInputs) => {
      setIsLoading(true);
      const { success, message } = await createPowerPlant({
        token: user?.token as string,
        userInput: data,
      });
      setIsLoading(false);

      if (success) {
        setAppMessage({
          title: 'Sucesso',
          content:
            'A usina foi criada. Agora você já pode vê-la na lista de usinar.',
        });
        return navigate(config.internalPaths.proposal);
      }

      setAppMessage({ title: 'Erro', content: message as string });
    },
    [setAppMessage, navigate, user, setIsLoading],
  );

  const handleUpdate = useCallback(
    async (data: CreatePowerPlantInputs) => {
      setIsLoading(true);
      const { success, message } = await updatePowerPlant({
        token: user?.token as string,
        powerPlantId: powerPlantId as string,
        userInput: data,
      });
      setIsLoading(false);

      if (success) {
        setAppMessage({
          title: 'Sucesso',
          content: 'A usina foi atualizada com sucesso.',
        });
        return navigate(config.internalPaths.adminHome);
      }

      setAppMessage({ title: 'Erro', content: message as string });
    },
    [setAppMessage, navigate, powerPlantId, user, setIsLoading],
  );

  const onSubmit: SubmitHandler<CreatePowerPlantInputs> = async (data) => {
    const { image } = data;
    let base64Image;

    if (image instanceof FileList && image.length > 0) {
      try {
        base64Image = await getBase64(image[0]);
      } catch (error) {
        setAppMessage({
          title: 'Erro',
          content: (error as Error).message,
        });
        return;
      }
    }

    const powerPlant = { ...data, image: base64Image };

    if (isCreatePowerPlantForm) {
      return handleCreate(powerPlant);
    }
    handleUpdate(powerPlant);
  };

  return (
    <AppContainer isLoading={isLoading} title={pageTitle.toUpperCase()}>
      <Container>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Container
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: '20px 630px',
              paddingTop: '50px',
            }}
          >
            <Container
              sx={{
                gridColumn: '1/2',
                gridRow: '1/2',
                alignSelf: 'center',
                paddingBottom: '20px',
              }}
            >
              <Typography sx={sectionSubtitleStyles}>
                USINA DESENVOLVIDA
              </Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '1/2',
                gridRow: '2/3',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'peakPowerInMWp',
                  label: 'MWp',
                  min: 0,
                  transform: {
                    mask: maskNumber(6),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'capacityFactor',
                  label: 'Fator de capacidade',
                  min: 0,
                  max: 1,
                  transform: {
                    mask: maskNumber(5),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(0.01),
                    formValueToInputValue: formValueToInputValue(100),
                  },
                },
                {
                  name: 'location',
                  label: 'Localização',
                  maxLength: 50,
                  minLength: 2,
                },
                {
                  name: 'powerDistributionCompany',
                  label: 'Concessionária da Usina',
                  isSelect: true,
                  children: powerDistributionCompanies.map(
                    (powerDistributionCompany: ListItem) => (
                      <MenuItem
                        key={powerDistributionCompany.id}
                        value={powerDistributionCompany.id}
                      >
                        {powerDistributionCompany.name}
                      </MenuItem>
                    ),
                  ),
                },
                {
                  name: 'monthlyLandLeasePerHa',
                  label: 'Valor da locação do terreno (R$/ha)',
                  min: 0,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'landPurchasePrice',
                  label: 'Valor da compra do terreno (R$)',
                  min: 0,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'landAreaInHa',
                  label: 'Tamanho do terreno (ha)',
                  min: 0,
                  transform: {
                    mask: parseInt,
                    unmask: parseInt,
                  },
                },
              ].map(({ children, ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                >
                  {children}
                </ControlledMaskedInput>
              ))}
              <Button sx={formItemStyles} variant="contained" component="label">
                Inserir imagem
                <input
                  {...register('image')}
                  hidden
                  accept="image/png"
                  type="file"
                />
              </Button>
            </Container>
            <Container
              sx={{
                gridColumn: '2/3',
                gridRow: '2/3',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'incentivizedEnergy',
                  label: 'Energia incentivada',
                  isSelect: true,
                  children: [
                    { value: 0, label: '0%' },
                    { value: 0.5, label: '50%' },
                    { value: 1, label: '100%' },
                  ].map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  )),
                },
                {
                  name: 'operationStartDate',
                  label: 'Data para operação comercial',
                  type: 'date',
                  shouldShrinkLabel: true,
                },
                {
                  name: 'name',
                  label: 'Nome da usina',
                  minLength: 2,
                  maxLength: 50,
                },
                {
                  name: 'sellingPricePerMWp',
                  label: 'Valor da usina (R$/MWp)',
                  min: 0,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'projectPricePerMWp',
                  label: 'Valor do projeto (R$/MWp)',
                  min: 0,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
                {
                  name: 'gridPricePerMWp',
                  label: 'Obra de rede estimado (R$/MWp)',
                  min: 0,
                  transform: {
                    mask: maskNumber(10),
                    unmask: unmaskNumber,
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                  },
                },
              ].map(({ children, ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                >
                  {children}
                </ControlledMaskedInput>
              ))}
            </Container>
          </Container>
          <Container
            sx={{
              display: 'flex',
              width: '100%',
              gap: '50px',
              justifyContent: 'center',
              marginBottom: '50px',
            }}
          >
            <Button
              variant="contained"
              color="warning"
              type="submit"
              disabled={isLoading}
              sx={{ width: '200px' }}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              href={config.internalPaths.proposal}
              disabled={isLoading}
              sx={{ width: '200px' }}
            >
              Cancelar
            </Button>
          </Container>
        </Box>
      </Container>
    </AppContainer>
  );
}
