import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { login } from '../api/auth/login';
import useGeneralAppState from '../hooks/general-app-state';
import { IUser } from '../interfaces';
import { useEffect } from 'react';
import { verifyToken } from '../api/auth/verify-token';
import {
  clearUserFromLocalStore,
  loadUserFromLocalStorage,
  saveUserInLocalStorage,
} from '../helpers/local-storage';
import { changePassword } from '../api/auth/change-password';

interface ICredentials {
  email: string;
  password: string;
}

interface ChangePasswordDto {
  email: string;
  password: string;
  newPassword: string;
}

interface IAuthContext {
  handleLogin: (credentials: ICredentials) => Promise<void>;
  handleChangePassword: (changePasswordDto: ChangePasswordDto) => Promise<void>;
  handleLogout: () => void;
  isCheckingAuthState: boolean;
  shouldRedirectToChangePassword: boolean;
  clearRedirectToChangePassword: () => void;
  user?: IUser;
}

export const AuthContext = createContext<IAuthContext>({
  handleLogin: (credentials: ICredentials) => Promise.resolve(),
  handleChangePassword: (changePasswordDto: ChangePasswordDto) =>
    Promise.resolve(),
  handleLogout: () => {},
  clearRedirectToChangePassword: () => {},
  shouldRedirectToChangePassword: false,
  isCheckingAuthState: false,
});
function Auth({ children }: React.PropsWithChildren) {
  const { setAppMessage } = useGeneralAppState();
  const [user, setUser] = useState<IUser>();
  const [isCheckingAuthState, setIsCheckingAuthState] = useState(true);
  const [shouldRedirectToChangePassword, setShouldRedirectToChangePassword] =
    useState(false);

  useEffect(() => {
    const loadedUser = loadUserFromLocalStorage();
    if (!loadedUser) {
      setIsCheckingAuthState(false);
      return;
    }

    const checkTokenValidity = async () => {
      const { success } = await verifyToken(loadedUser.token);
      setIsCheckingAuthState(false);

      if (success) {
        setUser(loadedUser);
        return;
      }
      clearUserFromLocalStore();
    };

    checkTokenValidity();
  }, []);

  const handleLogin = useCallback(
    async (credentials: ICredentials) => {
      const { success, message, redirect, ...userData } = await login(
        credentials,
      );

      if (redirect) {
        setAppMessage({
          title: 'Ação necessária',
          content: 'Por favor, troque sua senha antes de efetuar um novo login',
        });
        setShouldRedirectToChangePassword(true);
        return;
      }

      if (success) {
        setUser(userData);
        saveUserInLocalStorage(userData);
        return;
      }

      setUser(undefined);
      setAppMessage({
        title: 'Erro',
        content: message,
      });
    },
    [setAppMessage],
  );

  const handleChangePassword = useCallback(
    async (changePasswordDto: ChangePasswordDto) => {
      const { success, message } = await changePassword(changePasswordDto);
      if (success) {
        await handleLogin({
          email: changePasswordDto.email,
          password: changePasswordDto.newPassword,
        });
        return;
      }

      setAppMessage({
        title: 'Erro',
        content: message as string,
      });
    },
    [handleLogin, setAppMessage],
  );

  const clearRedirectToChangePassword = useCallback(() => {
    setShouldRedirectToChangePassword(false);
  }, []);

  const handleLogout = useCallback(() => {
    setUser(undefined);
    clearUserFromLocalStore();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleChangePassword,
        handleLogout,
        clearRedirectToChangePassword,
        isCheckingAuthState,
        shouldRedirectToChangePassword,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
