import React from 'react';
import { Button, MenuItem, TextField, Tooltip } from '@mui/material';
import {
  Control,
  FieldErrorsImpl,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import { MarketCategory, TaxType } from '../../../../common/enums/index';
import { ProposalInput } from '../input.interface';
import { ControlledMaskedInput } from '../../../../components/controlled-masked-input/index';
import {
  formValueToInputValue,
  inputValueToFormValue,
} from '../../../../common/helpers/input-value-to-form-value';
import {
  maskNumber,
  unmaskNumber,
} from '../../../../common/helpers/number-mask';
import { formItemStyles } from '../../../../common/styles/form-styles';

const marketCategoryLabelMap = new Map([
  [MarketCategory.FREE, 'MERCADO LIVRE'],
  [MarketCategory.CAPTIVE, 'MERCADO CATIVO'],
]);

const validateTaxExemptions = (value: TaxType[]) => {
  const errorMessage = 'Campo obrigatório';
  return (
    (Array.isArray(value) &&
      value.every((element) => Object.values(TaxType).includes(element))) ||
    errorMessage
  );
};

export function CommercialData({
  register,
  control,
  isLocalProject,
  errors,
  isReadOnly,
}: {
  register: UseFormRegister<ProposalInput>;
  control: Control<ProposalInput, any>;
  isLocalProject: boolean;
  errors: Partial<FieldErrorsImpl<ProposalInput>>;
  isReadOnly: boolean;
}) {
  const marketCategory = useWatch({
    control,
    name: 'consumerUnit.marketCategory',
  });
  return (
    <>
      <TextField
        inputProps={{ readOnly: true }}
        disabled
        value={isLocalProject ? 'LOCAL' : 'REMOTO'}
        label="Tipo de projeto"
        variant="filled"
        size="small"
        sx={formItemStyles}
      />
      {[
        {
          name: 'paybackExpectancyInYears',
          label: 'Expectativa de payback máximo (anos)',
          isSelect: true,
          children: Array(8)
            .fill(0)
            .map((_, index) => {
              const value = index + 5;
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            }),
        },
        {
          name: 'consumerUnit.taxExemptions',
          label: 'Isenção de tributos',
          isSelect: true,
          isRequired: false,
          validate: {
            isValidArrayOfTaxTypes: validateTaxExemptions,
          },
          transform: {
            formValueToInputValue: (value: string[]) =>
              Array.isArray(value) ? JSON.stringify(value) : '',
            inputValueToFormValue: (value: string) =>
              value ? JSON.parse(value) : '',
          },
          children: [
            { value: JSON.stringify([]), label: 'NÃO' },
            { value: JSON.stringify([TaxType.ICMS]), label: 'ICMS' },
            {
              value: JSON.stringify([TaxType.PIS_COFINS]),
              label: 'PIS/COFINS',
            },
            {
              value: JSON.stringify([TaxType.PIS_COFINS, TaxType.ICMS]),
              label: 'ICMS E PIS/COFINS',
            },
          ].map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          )),
        },
        {
          name: 'wishesToOutsourceManagement',
          label: 'Gestão da usina e da UC',
          isSelect: true,
          isRequired: false,
          validate: {
            isBoolean: (value: boolean | string) => {
              return typeof value === 'boolean' || 'Campo obrigatório';
            },
          },
          transform: {
            formValueToInputValue: (value: boolean) =>
              typeof value === 'boolean' ? String(value) : '',
            inputValueToFormValue: (value: string) =>
              value === String(true)
                ? true
                : value === String(false)
                ? false
                : undefined,
          },
          children: [
            { value: String(true), label: 'SIM' },
            { value: String(false), label: 'NÃO' },
          ].map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          )),
        },
        {
          name: 'consumerUnit.pisCofins',
          label: 'PIS/COFINS (%)',
          max: 1,
          min: 0,
          transform: {
            inputValueToFormValue: inputValueToFormValue(0.01),
            formValueToInputValue: formValueToInputValue(100),
            mask: maskNumber(5),
            unmask: unmaskNumber,
          },
        },
        {
          name: 'consumerUnit.incentivizedEnergy',
          label: 'Energia incentivada atual',
          isSelect: true,
          children: [
            { value: 0, label: '0%' },
            { value: 0.5, label: '50%' },
            { value: 1, label: '100%' },
          ].map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          )),
        },
        {
          name: 'consumerUnit.marketCategory',
          label: 'Mercado atual da UC',
          isSelect: true,
          children: Object.entries(MarketCategory).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {marketCategoryLabelMap.get(value as MarketCategory)}
            </MenuItem>
          )),
        },
      ].map(({ children, ...props }) => (
        <ControlledMaskedInput
          isRequired
          key={props.label}
          {...props}
          control={control}
          errors={errors}
          isReadOnly={isReadOnly}
        >
          {children}
        </ControlledMaskedInput>
      ))}

      {marketCategory === MarketCategory.FREE && (
        <ControlledMaskedInput
          isRequired
          name="consumerUnit.freeMarketEnergyPricePerMWh"
          label="Valor da energia* (R$/MWh)"
          min={0}
          transform={{
            inputValueToFormValue: inputValueToFormValue(1),
            formValueToInputValue: formValueToInputValue(1),
            mask: maskNumber(6),
            unmask: unmaskNumber,
          }}
          tooltip={{
            title: '* Energia referente ao mercado livre',
            placement: 'right',
          }}
          control={control}
          errors={errors}
          isReadOnly={isReadOnly}
        />
      )}
      {!isReadOnly && (
        <Tooltip title="Anexar fatura da conta de energia e fatura da conta da distribuidora">
          <Button sx={formItemStyles} variant="contained" component="label">
            Carregar anexos
            <input
              hidden
              {...register('files')}
              accept=".pdf"
              multiple
              type="file"
            />
          </Button>
        </Tooltip>
      )}
    </>
  );
}
