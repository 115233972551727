import React, { useEffect, useState } from 'react';
import { Container, MenuItem } from '@mui/material';
import { Control, FieldErrorsImpl } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ElectricityFeeType, ProposalType } from '../../../../common/enums';
import { ProposalInput } from '../input.interface';
import { listPowerPlants } from '../../../../common/api/power-plants/list-power-plants';
import useAuth from '../../../../common/hooks/auth';
import useGeneralAppState from '../../../../common/hooks/general-app-state';
import { config } from '../../../../common/config/base';
import { listPowerDistributionCompanies } from '../../../../common/api/power-distribution-companies/list-pdcs';
import { ControlledMaskedInput } from '../../../../components/controlled-masked-input';
import {
  maskNumber,
  unmaskNumber,
} from '../../../../common/helpers/number-mask';
import {
  formValueToInputValue,
  inputValueToFormValue,
} from '../../../../common/helpers/input-value-to-form-value';

const electricityFeeTypeLabelMap = new Map([
  [ElectricityFeeType.BLUE, 'AZUL'],
  [ElectricityFeeType.GREEN, 'VERDE'],
]);

// @TODO: this is being used in multiple places, centralize it
interface ListItem {
  name: string;
  id: string;
}

export function TechnicalData({
  proposalType,
  control,
  errors,
  isReadOnly,
}: {
  proposalType: ProposalType;
  control: Control<ProposalInput, any>;
  errors: Partial<FieldErrorsImpl<ProposalInput>>;
  isReadOnly: boolean;
}) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setAppMessage } = useGeneralAppState();
  const [powerPlants, setPowerPlants] = useState([]);
  const [powerDistributionCompanies, setPowerDistributionCompanies] = useState(
    [],
  );

  useEffect(() => {
    const handleFetchError = (message?: string) => {
      if (message) {
        setAppMessage({
          title: 'Error',
          content: message,
        });
      }
      navigate(config.internalPaths.proposal);
    };

    if (!user) {
      handleFetchError();
      return;
    }

    const fetchPowerPlants = async () => {
      const { success, message, data } = await listPowerPlants({
        token: user.token,
      });

      if (success) {
        setPowerPlants(data);
        return;
      }

      handleFetchError(message);
    };

    const fetchPowerDistributionCompanies = async () => {
      const { success, message, data } = await listPowerDistributionCompanies(
        user.token,
      );

      if (success) {
        setPowerDistributionCompanies(data);
        return;
      }

      handleFetchError(message);
    };

    if (proposalType === ProposalType.PURCHASE) {
      fetchPowerPlants();
    }
    fetchPowerDistributionCompanies();
  }, [user, setAppMessage, navigate, proposalType]);

  return (
    <>
      {[
        {
          name: 'consumerUnit.offPeakEnergyConsumptionInMWh',
          label: 'Consumo FP (KWh/mês)',
          min: 0,
          transform: {
            inputValueToFormValue: inputValueToFormValue(0.001),
            formValueToInputValue: formValueToInputValue(1000),
            mask: maskNumber(14),
            unmask: unmaskNumber,
          },
        },
        {
          name: 'consumerUnit.peakEnergyConsumptionInMWh',
          label: 'Consumo P (KWh/mês)',
          min: 0,
          transform: {
            inputValueToFormValue: inputValueToFormValue(0.001),
            formValueToInputValue: formValueToInputValue(1000),
            mask: maskNumber(14),
            unmask: unmaskNumber,
          },
        },
        {
          name: 'consumerUnit.offPeakPowerConsumptionInKW',
          label: 'Demanda FP (KW)',
          min: 0,
          transform: {
            inputValueToFormValue: inputValueToFormValue(1),
            formValueToInputValue: formValueToInputValue(1),
            mask: maskNumber(14),
            unmask: unmaskNumber,
          },
        },
        {
          name: 'consumerUnit.peakPowerConsumptionInKW',
          label: 'Demanda P (KW)',
          min: 0,
          transform: {
            inputValueToFormValue: inputValueToFormValue(1),
            formValueToInputValue: formValueToInputValue(1),
            mask: maskNumber(14),
            unmask: unmaskNumber,
          },
        },
        {
          name: 'simultaneity',
          label: 'Simultaneidade (%)',
          min: 0,
          max: 1,
          hidden: proposalType !== ProposalType.LOCAL_DEVELOPMENT,
          transform: {
            inputValueToFormValue: inputValueToFormValue(0.01),
            formValueToInputValue: formValueToInputValue(100),
            mask: maskNumber(5),
            unmask: unmaskNumber,
          },
        },
        {
          name: 'consumerUnit.availableRoofAreaInM2',
          label: 'Área disponível no telhado (m²)',
          min: 1,
          hidden: proposalType !== ProposalType.LOCAL_DEVELOPMENT,
          transform: {
            inputValueToFormValue: inputValueToFormValue(1),
            formValueToInputValue: formValueToInputValue(1),
            mask: maskNumber(14),
            unmask: unmaskNumber,
          },
        },
        {
          name: 'consumerUnit.numberOfUnits',
          label: 'Número de unidades consumidoras',
          min: 0,
          max: 100,
          transform: {
            unmask: parseInt,
          },
          tooltip: {
            title:
              'Número total de UCs consideradas para se chegar nos valores acima',
            placement: 'right',
          },
        },
        {
          name: 'electricityFeeType',
          label: 'Tarifa',
          isSelect: true,
          children: Object.entries(ElectricityFeeType).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {electricityFeeTypeLabelMap.get(value as ElectricityFeeType)}
            </MenuItem>
          )),
        },
        {
          name: 'consumerUnit.powerDistributionCompany',
          label: 'Concessionária da UC',
          isSelect: true,
          children: powerDistributionCompanies.map(
            (powerDistributionCompany: ListItem) => (
              <MenuItem
                key={powerDistributionCompany.id}
                value={powerDistributionCompany.id}
              >
                {powerDistributionCompany.name}
              </MenuItem>
            ),
          ),
        },
      ].map(({ children, hidden, ...props }) => {
        return hidden ? (
          <Container key={props.label} />
        ) : (
          <ControlledMaskedInput
            key={props.label}
            {...props}
            control={control}
            errors={errors}
            isRequired
            isReadOnly={isReadOnly}
          >
            {children}
          </ControlledMaskedInput>
        );
      })}

      {proposalType === ProposalType.PURCHASE && (
        <ControlledMaskedInput
          name="powerPlant"
          label="Usina a adquirir"
          isSelect
          control={control}
          errors={errors}
          isRequired
          tooltip={{
            title: 'Selecione a usina que será comprada',
            placement: 'right',
          }}
          isReadOnly={isReadOnly}
        >
          {powerPlants?.map((powerPlant: ListItem) => (
            <MenuItem key={powerPlant.id} value={powerPlant.id}>
              {powerPlant.name}
            </MenuItem>
          ))}
        </ControlledMaskedInput>
      )}
    </>
  );
}
