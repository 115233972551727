import { IUser } from '../interfaces';

const localStorageAuthKey = 'ng3-auth';

export const loadUserFromLocalStorage = () => {
  const localStoreItem = localStorage.getItem(localStorageAuthKey);
  return localStoreItem === null ? null : (JSON.parse(localStoreItem) as IUser);
};

export const saveUserInLocalStorage = (userData: IUser) => {
  localStorage.setItem(localStorageAuthKey, JSON.stringify(userData));
};

export const clearUserFromLocalStore = () => {
  localStorage.removeItem(localStorageAuthKey);
};
