import axios from 'axios';
import { PowerDistributionCompanyDto } from '../../../pages/power-distribution-companies/index';
import { getApiInstance } from '../api';

export const updatePowerDistributionCompanies = async ({
  token,
  userInput,
}: {
  token: string;
  userInput: PowerDistributionCompanyDto[];
}) => {
  const api = getApiInstance(token);
  try {
    await api.put(`/power-distribution-companies`, {
      powerDistributionCompanies: userInput,
    });

    return { success: true };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error) && error.response?.status) {
      if (error.response?.status === 400) {
        const apiMessage = error?.response?.data?.message;
        const arrayOfMessages =
          typeof apiMessage === 'string' ? [apiMessage] : apiMessage || [];
        const message = [
          'Erro ao salvar concessionárias.',
          ...arrayOfMessages.map(translateError),
        ].join(' ');
        return {
          success: false,
          message,
        };
      }
      return {
        success: false,
        message: `Erro ao salvar concessionárias. ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao salvar concessionárias. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};

const translateError = (error?: string) => {
  if (!error) return [];

  const dictionary = new Map([
    ['powerDistributionCompanies', 'Na concessionária'],
    ['blueA4', 'azulA4'],
    ['greenA4', 'verdeA4'],
    ['electricityFees', 'a tarifa'],
  ]);

  const translatedTerms = error
    .split('.')
    .map((englishTerm) => {
      if (dictionary.has(englishTerm)) {
        return dictionary.get(englishTerm);
      }

      if (/^\d+$/.test(englishTerm)) {
        return `número ${Number(englishTerm) + 1}, `;
      }

      return englishTerm;
    })
    .join(' ');

  return `${translatedTerms}.`;
};
