import { getApiInstance } from '../api';

export const getPowerPlantById = async ({
  token,
  powerPlantId,
}: {
  token: string;
  powerPlantId: string;
}) => {
  const api = getApiInstance(token);
  try {
    const { data } = await api.get(`/power-plants/${powerPlantId}`);

    return { success: true, data };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      message: `Erro ao carregar usina. ${(error as Error).message}`,
    };
  }
};
