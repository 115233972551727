import React, { useMemo } from 'react';
import { ReactElement } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { formatNumber } from './edit-pdf/common/general-number-formatter';

export const AccumulatedCashFlowChart = ({
  innerRef,
  cashFlowAccumulation,
}: {
  cashFlowAccumulation: number[];
  innerRef: any;
}): ReactElement => {
  const data = useMemo(
    () =>
      cashFlowAccumulation?.slice(1, 26).map((value: number, i: number) => ({
        id: i + 1,
        value,
      })),
    [cashFlowAccumulation],
  );

  if (!cashFlowAccumulation) {
    return <div></div>;
  }
  return (
    <div ref={innerRef}>
      <BarChart
        width={700}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 40,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3" vertical={false} />
        <XAxis dataKey="id" interval={0} axisLine={false} />
        <YAxis
          tickFormatter={(value) => `R$${formatNumber(value / 1_000_000)}MM`}
        />
        <Tooltip />
        <Bar dataKey="value" fill="#002060" isAnimationActive={false} />
      </BarChart>
    </div>
  );
};
