import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useDebounce } from 'usehooks-ts';

import { AccumulatedCashFlowChart } from './cash-flow-chart';
import { getProposalPdfData } from '../../../common/api/proposals/get-pdf-data';
import useAuth from '../../../common/hooks/auth';
import Loading from '../../../components/loading/index';
import { ProposalType } from '../../../common/enums';
import { config } from '../../../common/config/base';
import useGeneralAppState from '../../../common/hooks/general-app-state';
import { FullEpcTable } from './full-epc-table';
import { EconomyTable } from './economy-table';
import { FinancialFlowSummaryTable } from './financial-flow-summary-table';

const getGenerateProposalPdfMethod = async (
  proposalType: ProposalType,
): Promise<(params: any) => Promise<Blob>> => {
  switch (proposalType) {
    case ProposalType.PURCHASE: {
      const { generatePurchaseProposalPdf } = await import(
        './edit-pdf/purchase/generate-pdf'
      );
      return generatePurchaseProposalPdf;
    }
    case ProposalType.REMOTE_DEVELOPMENT: {
      const { generateRemoteDevelopmentProposalPdf } = await import(
        './edit-pdf/remote-development/generate-pdf'
      );
      return generateRemoteDevelopmentProposalPdf;
    }
    case ProposalType.LOCAL_DEVELOPMENT: {
      const { generateLocalDevelopmentProposalPdf } = await import(
        './edit-pdf/local-development/generate-pdf'
      );
      return generateLocalDevelopmentProposalPdf;
    }
    default: {
      throw Error(`o tipo da proposta '${proposalType}' é inválido`);
    }
  }
};

const Renderer = () => {
  const navigate = useNavigate();
  const { setAppMessage } = useGeneralAppState();
  const { user } = useAuth();
  const { proposalId } = useParams();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [template, setTemplate] = useState<any>();
  const [proposalData, setProposalData] = useState<any>();
  const cashFlowChartRef = useRef();
  const fullEpcTableRef = useRef();
  const economyTableRef = useRef();
  const financialFlowSummaryTableRef = useRef();
  const debouncedProposalData = useDebounce(proposalData, 100);

  const loadData = useCallback(async () => {
    if (!user || !proposalId) return;

    try {
      const {
        data: { template, data },
      } = await getProposalPdfData({
        token: user.token,
        proposalId: proposalId,
      });

      setTemplate(template);
      setProposalData(data);
    } catch (error) {
      setAppMessage({
        title: 'Erro',
        content: `Houve um problema ao carregar os dados da sua proposta: ${
          (error as Error)?.message || error
        }`,
      });
      return navigate(config.internalPaths.adminHome);
    }
  }, [user, proposalId, setAppMessage, navigate]);

  const modifyPdf = useCallback(async () => {
    if (!debouncedProposalData || !template) return;

    try {
      const generateProposalPdf = await getGenerateProposalPdfMethod(
        debouncedProposalData.proposalType,
      );

      const pdfBlob = await generateProposalPdf({
        template,
        proposalData: debouncedProposalData,
        componentRefs: {
          cashFlowChartRef,
          fullEpcTableRef,
          economyTableRef,
          financialFlowSummaryTableRef,
        },
      });

      const dataUrl = window.URL.createObjectURL(pdfBlob);
      setPdfUrl(dataUrl);
    } catch (error) {
      if (pdfUrl) {
        return;
      }
      setAppMessage({
        title: 'Error',
        content: `Houve um problema ao montar o pdf da sua proposta: ${
          (error as Error).message
        }`,
      });
      return navigate(config.internalPaths.adminHome);
    }
  }, [
    debouncedProposalData,
    template,
    cashFlowChartRef,
    setAppMessage,
    navigate,
    pdfUrl,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    modifyPdf();
  }, [modifyPdf]);

  if (pdfUrl) {
    setAppMessage({
      title: 'Sucesso',
      content:
        'O pdf da sua proposta foi gerada com sucesso. Clique %aneste link%a para abri-la.',
      url: pdfUrl,
    });
    navigate(config.internalPaths.adminHome);
    return <></>;
  }

  return (
    <>
      <Box
        id="box-covering-elements"
        sx={{
          position: 'fixed',
          zIndex: 1,
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
        }}
      />
      <Container>
        <AccumulatedCashFlowChart
          innerRef={cashFlowChartRef}
          cashFlowAccumulation={proposalData?.cashFlow?.accumulation}
        />
      </Container>
      <Container maxWidth={false}>
        <FullEpcTable proposalData={proposalData} innerRef={fullEpcTableRef} />
        <EconomyTable proposalData={proposalData} innerRef={economyTableRef} />
        <FinancialFlowSummaryTable
          proposalData={proposalData}
          innerRef={financialFlowSummaryTableRef}
        />
      </Container>
      <Loading isLoading={true}></Loading>
    </>
  );
};

export default Renderer;
