import { getApiInstance } from '../api';

export const getIntegratorById = async ({
  token,
  integratorId,
}: {
  token: string;
  integratorId: string;
}) => {
  const api = getApiInstance(token);
  try {
    const { data } = await api.get(`/integrators/${integratorId}`);

    return { success: true, data };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      message: `Erro ao carregar integrador. ${(error as Error).message}`,
    };
  }
};
