import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getApiInstance = (token?: string) =>
  axios.create({
    baseURL,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });
