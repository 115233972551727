import React, { useState, useCallback, useEffect } from 'react';
import { Container } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { EditOutlined, HighlightOff } from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import useAuth from '../../../common/hooks/auth';
import useGeneralAppState from '../../../common/hooks/general-app-state';
import { config } from '../../../common/config/base';
import ConfirmationDialog from '../../../components/confirmation-dialog/index';
import { listPowerPlants } from '../../../common/api/power-plants/list-power-plants';
import { deletePowerPlant } from '../../../common/api/power-plants/delete-power-plant';
import {
  firstTableCellStyles,
  iconStyles,
  otherTableCellStyles,
  tableRowStyles,
} from '../styles/table';
import { confirmDialogInitialProps } from '../../../components/confirmation-dialog/initial-props';

interface PowerPlantListDto {
  id: string;
  name: string;
}

export function PowerPlantTable() {
  const theme = useTheme();
  const { setAppMessage } = useGeneralAppState();
  const { user, handleLogout } = useAuth();
  const [powerPlantList, setPowerPlantList] = useState<PowerPlantListDto[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState(
    confirmDialogInitialProps,
  );

  const rowsPerPage = 5;
  const emptyRows = isLoading
    ? rowsPerPage
    : rowsPerPage - powerPlantList.length;

  const getPowerPlants = useCallback(async () => {
    if (!user) return;
    const { token } = user;

    setIsLoading(true);
    const {
      success,
      message,
      data,
      count: powerPlantCount,
    } = await listPowerPlants({
      token,
      page,
    });
    setIsLoading(false);

    if (success) {
      setCount(powerPlantCount);
      setPowerPlantList(data);
      return;
    }
    setAppMessage({
      title: 'Error',
      content: message as string,
    });
    handleLogout();
  }, [handleLogout, setAppMessage, user, page]);

  const handleDelete = useCallback(
    async (powerPlantId: string) => {
      setConfirmationDialogProps(confirmDialogInitialProps);
      setIsLoading(true);
      const { success, message } = await deletePowerPlant({
        powerPlantId,
        token: user?.token as string,
      });
      setIsLoading(false);

      if (success) {
        getPowerPlants();
        setAppMessage({
          title: 'Sucesso',
          content: 'A usina foi deletada.',
        });
        return;
      }

      setAppMessage({
        title: 'Error',
        content: message as string,
      });
    },
    [user, setAppMessage, getPowerPlants],
  );

  const handleConfirmDelete = useCallback(
    (powerPlantId: string) => () => {
      setConfirmationDialogProps({
        isVisible: true,
        title: 'Atenção',
        message: 'Deletar usina? Esta ação não pode ser revertida.',
        handleReject: () =>
          setConfirmationDialogProps(confirmDialogInitialProps),
        handleConfirm: () => handleDelete(powerPlantId),
      });
    },
    [handleDelete],
  );

  useEffect(() => {
    getPowerPlants();
  }, [getPowerPlants, user]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const tableHeaderSettings = {
    fontWeight: 800,
    color: theme.palette.primary.dark,
  };

  return (
    <Container
      sx={{
        paddingTop: '100px',
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '650px' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={firstTableCellStyles}>
                <Typography sx={tableHeaderSettings}>Usina</Typography>
              </TableCell>
              <TableCell sx={otherTableCellStyles} align="center">
                <Typography sx={tableHeaderSettings}>Excluir</Typography>
              </TableCell>
              <TableCell sx={otherTableCellStyles} align="center">
                <Typography sx={tableHeaderSettings}>Editar</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              powerPlantList?.map((powerPlant) => (
                <TableRow
                  sx={{
                    height: `${tableRowStyles.height}px`,
                    '&:nth-of-type(odd)': {
                      backgroundColor: theme.palette.action.hover,
                    },
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                  key={powerPlant.id}
                >
                  <TableCell
                    sx={firstTableCellStyles}
                    component="th"
                    scope="row"
                  >
                    {powerPlant.name}
                  </TableCell>
                  <TableCell sx={otherTableCellStyles} align="center">
                    <IconButton
                      sx={iconStyles}
                      onClick={handleConfirmDelete(powerPlant.id)}
                    >
                      <HighlightOff />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={otherTableCellStyles} align="center">
                    <IconButton
                      sx={iconStyles}
                      href={config.internalPaths.editPowerPlant.replace(
                        ':powerPlantId',
                        powerPlant.id,
                      )}
                    >
                      <EditOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                sx={{
                  height: `${tableRowStyles.height * emptyRows}px`,
                }}
              >
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={Paper}
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        page={page}
        onPageChange={handleChangePage}
      ></TablePagination>
      <ConfirmationDialog {...confirmationDialogProps}></ConfirmationDialog>
    </Container>
  );
}
