export const sectionSubtitleStyles = {
  fontSize: '1em',
  fontWeight: 600,
};

export const formItemStyles = {
  marginTop: '10px',
  marginLeft: '30px',
  width: '70%',
};

export const menuStyles = {
  maxHeight: '200px',
};
