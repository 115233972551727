const internalPaths = {
  landing: '/',
  login: '/login',
  changePassword: '/trocar-senha',
  proposal: {
    base: '/propostas',
    create: '/criar',
    edit: '/:proposalId',
    pdf: '/:proposalId/pdf',
  },
  userProfile: '/perfil',
  adminHome: '/home',
  adminSettings: '/configurar',
  integrator: {
    base: '/integradores',
    create: '/criar',
    edit: '/:integratorId',
    proposal: '/:integratorId/propostas',
  },
  powerPlant: {
    base: '/usinas',
    create: '/criar',
    edit: '/:powerPlantId',
  },
  powerDistributionCompanies: '/concessionarias',
};

export const config = {
  internalPaths: {
    landing: internalPaths.landing,
    login: internalPaths.login,
    changePassword: internalPaths.changePassword,
    proposal: internalPaths.proposal.base,
    createProposal: `${internalPaths.proposal.base}${internalPaths.proposal.create}`,
    editProposal: `${internalPaths.proposal.base}${internalPaths.proposal.edit}`,
    createIntegrator: `${internalPaths.integrator.base}${internalPaths.integrator.create}`,
    editIntegrator: `${internalPaths.integrator.base}${internalPaths.integrator.edit}`,
    integratorProposal: `${internalPaths.integrator.base}${internalPaths.integrator.proposal}`,
    createPowerPlant: `${internalPaths.powerPlant.base}${internalPaths.powerPlant.create}`,
    editPowerPlant: `${internalPaths.powerPlant.base}${internalPaths.powerPlant.edit}`,
    adminHome: internalPaths.adminHome,
    adminSettings: internalPaths.adminSettings,
    powerDistributionCompanies: internalPaths.powerDistributionCompanies,
    userProfile: internalPaths.userProfile,
    proposalPdf: `${internalPaths.proposal.base}${internalPaths.proposal.pdf}`,
  },
};
