import React from 'react';
import { Box, Typography } from '@mui/material';

export function Footer({
  color,
  backgroundColor,
}: {
  color?: string;
  backgroundColor?: string;
}) {
  return (
    <Box
      sx={{
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 36,
        backgroundColor: backgroundColor || '#BABABA',
        color: color || 'rgba(40, 40, 40, 0.4)',
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 1,
      }}
    >
      <Typography>
        TODOS OS DIREITOS RESERVADOS | {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}
