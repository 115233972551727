import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Control, FieldErrorsImpl } from 'react-hook-form';
import emailValidator from 'email-validator';
import { State } from '../../../common/enums';
import { ProposalInput } from './input.interface';
import { ControlledMaskedInput } from '../../../components/controlled-masked-input/index';
import { sectionSubtitleStyles } from '../../../common/styles/form-styles';

export function CustomerData({
  control,
  errors,
  isReadOnly,
}: {
  control: Control<ProposalInput, any>;
  errors: Partial<FieldErrorsImpl<ProposalInput>>;
  isReadOnly: boolean;
}) {
  return (
    <Container
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '20px 300px',
        paddingTop: '50px',
      }}
    >
      <Container
        sx={{
          gridColumn: '1/3',
          gridRow: '1/2',
          alignSelf: 'center',
          paddingBottom: '20px',
        }}
      >
        <Typography sx={sectionSubtitleStyles}>CONTATO DO CLIENTE</Typography>
      </Container>
      <Container
        sx={{
          gridColumn: '1/2',
          gridRow: '2/6',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {[
          {
            name: 'customer.name',
            label: 'Nome',
          },
          {
            name: 'customer.phone',
            label: 'Telefone',
            pattern: {
              value:
                /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
              message: 'Deve ser um número de telefone nacional válido',
            },
          },
          {
            name: 'customer.email',
            label: 'E-mail',
            validate: {
              isValidEmail: (email: string) =>
                emailValidator.validate(email) || 'Insira um email válido',
            },
          },
          {
            name: 'customer.document',
            label: 'CNPJ',
            minLength: 11,
            maxLength: 20,
          },
          {
            name: 'customer.address.postalCode',
            label: 'CEP',
            validate: {
              isValidPostalCode: (value: string) => {
                return /^[0-9]{5}-[0-9]{3}$/.test(value) || 'CEP inválido';
              },
            },
            transform: {
              mask: (value: string) =>
                value
                  ?.replace(/\D/g, '')
                  .slice(0, 8)
                  .replace(/(\d)(\d{3})$/, '$1-$2'),
            },
          },
        ].map(({ ...props }) => (
          <ControlledMaskedInput
            key={props.label}
            {...props}
            control={control}
            errors={errors}
            isRequired
            isReadOnly={isReadOnly}
          />
        ))}
      </Container>
      <Container
        sx={{
          gridColumn: '2/3',
          gridRow: '2/6',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {[
          {
            name: 'customer.address.streetAddress',
            label: 'Endereço',
            minLength: 2,
            maxLength: 50,
          },
          {
            name: 'customer.address.streetAddressNumber',
            label: 'Número',
            maxLength: 10,
          },
          {
            name: 'customer.address.subLocality',
            label: 'Bairro',
            minLength: 2,
            maxLength: 50,
          },
          {
            name: 'customer.address.locality',
            label: 'Cidade',
            minLength: 2,
            maxLength: 50,
          },
          {
            name: 'customer.address.region',
            label: 'Estado',
            isSelect: true,
            children: Object.entries(State).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            )),
          },
        ].map(({ children, ...props }) => (
          <ControlledMaskedInput
            key={props.label}
            {...props}
            control={control}
            errors={errors}
            isRequired
            isReadOnly={isReadOnly}
          >
            {children}
          </ControlledMaskedInput>
        ))}
      </Container>
    </Container>
  );
}
