import React, { useCallback, useState } from 'react';
import { Container } from '@mui/system';
import emailValidator from 'email-validator';
import { Box, Button, MenuItem, Typography } from '@mui/material';

import { SubmitHandler, useForm } from 'react-hook-form';

import { Role, State } from '../../common/enums';
import { config } from '../../common/config/base';
import { ControlledMaskedInput } from '../../components/controlled-masked-input';
import {
  formItemStyles,
  sectionSubtitleStyles,
} from '../../common/styles/form-styles';
import {
  formValueToInputValue,
  inputValueToFormValue,
} from '../../common/helpers/input-value-to-form-value';
import { maskNumber, unmaskNumber } from '../../common/helpers/number-mask';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../common/hooks/auth';
import { getIntegratorById } from '../../common/api/integrators/get-integrator-by-id';
import useGeneralAppState from '../../common/hooks/general-app-state';
import { updateIntegrator } from '../../common/api/integrators/update-integrator';
import { createIntegrator } from '../../common/api/integrators/create-integrator';
import { AppContainer } from '../../components/app-container';
import { getBase64 } from '../../common/helpers/convert-file-to-base64';

// @TODO: move interfaces to proper file
// @TODO: re-use postal address on proposal
interface PostalAddress {
  country: string;
  locality: string;
  subLocality: string;
  region: State;
  postalCode: string;
  streetAddress: string;
  streetAddressNumber: string;
}

export interface UpdateIntegratorInputs {
  email: string;
  name: string;
  phone: string;
  address: PostalAddress;
  document: string;
  commissionValuePerMWp: number;
  doesLocalWork: boolean;
  logo?: FileList | string;
}

export interface CreateIntegratorInputs {
  integrator: UpdateIntegratorInputs;
  role: Role;
  email?: string;
  password?: string;
  confirmPassword?: string;
}

const initialState = {
  integrator: {
    name: '',
    phone: '',
    document: '',
    email: '',
    address: {
      country: 'BR',
      locality: '',
      subLocality: '',
      postalCode: '',
      streetAddress: '',
      streetAddressNumber: '',
    },
  },
  role: Role.USER,
  email: '',
  password: '',
  confirmPassword: '',
};

//@TODO: Separate sections into different components
export function IntegratorForm() {
  const { integratorId: urlIntegratorId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setAppMessage } = useGeneralAppState();

  const [isLoading, setIsLoading] = useState(false);

  const isUser = user?.role === Role.USER;
  const integratorId = isUser ? user.integratorId : urlIntegratorId;
  const isCreateIntegratorForm = !integratorId;

  const pageTitle = isUser
    ? 'meu perfil'
    : isCreateIntegratorForm
    ? 'criar integrador'
    : 'editar integrador';

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    register,
  } = useForm<CreateIntegratorInputs>({
    defaultValues: async () => {
      if (isCreateIntegratorForm) {
        return initialState as CreateIntegratorInputs;
      }

      setIsLoading(true);
      const { success, message, data } = await getIntegratorById({
        token: user?.token as string,
        integratorId,
      });
      setIsLoading(false);

      if (success) {
        return {
          integrator: data,
        } as CreateIntegratorInputs;
      }

      setAppMessage({
        title: 'Erro',
        content: message as string,
      });
      return {} as CreateIntegratorInputs;
    },
  });

  const handleCreate = useCallback(
    async (data: CreateIntegratorInputs) => {
      setIsLoading(true);
      const { success, message } = await createIntegrator({
        token: user?.token as string,
        userInput: data,
      });
      setIsLoading(false);

      if (success) {
        setAppMessage({
          title: 'Sucesso',
          content:
            'O integrador foi criado. Agora ele ja poderá acessar a plataforma com as credenciais que você escolheu.',
        });
        return navigate(config.internalPaths.proposal);
      }

      setAppMessage({ title: 'Erro', content: message as string });
    },
    [setAppMessage, navigate, user, setIsLoading],
  );

  const handleUpdate = useCallback(
    async (data: CreateIntegratorInputs) => {
      const userInput = data.integrator;

      setIsLoading(true);
      const { success, message } = await updateIntegrator({
        token: user?.token as string,
        integratorId: integratorId as string,
        userInput,
      });
      setIsLoading(false);

      if (success) {
        setAppMessage({
          title: 'Sucesso',
          content: 'O integrador foi atualizado com sucesso.',
        });
        return navigate(config.internalPaths.adminHome);
      }

      setAppMessage({ title: 'Erro', content: message as string });
    },
    [setAppMessage, navigate, integratorId, user, setIsLoading],
  );

  const onSubmit: SubmitHandler<CreateIntegratorInputs> = async (data) => {
    if (isUser) return;

    const { logo } = data.integrator;
    let base64Logo;
    if (logo instanceof FileList && logo.length > 0) {
      try {
        base64Logo = await getBase64(logo[0]);
      } catch (error) {
        setAppMessage({
          title: 'Erro',
          content: (error as Error).message,
        });
        return;
      }
    }
    const integrator = { ...data.integrator, logo: base64Logo };
    if (isCreateIntegratorForm) {
      return handleCreate({ ...data, integrator });
    }
    handleUpdate({ ...data, integrator });
  };

  return (
    <AppContainer isLoading={isLoading} title={pageTitle.toUpperCase()}>
      <Container>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Container
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: '20px repeat(3, 73px) 20px repeat(7, 73px)',
              paddingTop: '50px',
            }}
          >
            <Container
              sx={{
                gridColumn: '1/2',
                gridRow: '1/2',
                alignSelf: 'center',
                paddingBottom: '20px',
              }}
            >
              <Typography sx={sectionSubtitleStyles}>
                CONTATO INTEGRADOR
              </Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '2/3',
                gridRow: '1/2',
                alignSelf: 'center',
                paddingBottom: '20px',
              }}
            >
              <Typography sx={sectionSubtitleStyles}>COMISSÃO</Typography>
            </Container>
            <Container
              sx={{
                gridColumn: '1/2',
                gridRow: '2/13',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'integrator.name',
                  label: 'Nome',
                  minLength: 2,
                  maxLength: 100,
                },
                {
                  name: 'integrator.phone',
                  label: 'Telefone',
                  maxLength: 20,
                  pattern: {
                    value:
                      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
                    message: 'Deve ser um número de telefone nacional válido',
                  },
                },
                {
                  name: 'integrator.email',
                  label: 'E-mail',
                  maxLength: 320,
                  validate: {
                    isValidEmail: (email: string) =>
                      emailValidator.validate(email) ||
                      'Insira um email válido',
                  },
                },
                {
                  name: 'integrator.document',
                  label: 'CNPJ',
                  minLength: 11,
                  maxLength: 20,
                },
                {
                  name: 'integrator.address.postalCode',
                  label: 'CEP',
                  validate: {
                    isValidPostalCode: (value: string) => {
                      return (
                        /^[0-9]{5}-[0-9]{3}$/.test(value) || 'CEP inválido'
                      );
                    },
                  },
                  transform: {
                    mask: (value: string) =>
                      value
                        ?.replace(/\D/g, '')
                        .slice(0, 8)
                        .replace(/(\d)(\d{3})$/, '$1-$2'),
                  },
                },
                {
                  name: 'integrator.address.streetAddress',
                  label: 'Endereço',
                  minLength: 2,
                  maxLength: 50,
                },
                {
                  name: 'integrator.address.streetAddressNumber',
                  label: 'Número',
                  maxLength: 10,
                },
                {
                  name: 'integrator.address.subLocality',
                  label: 'Bairro',
                  minLength: 2,
                  maxLength: 50,
                },
                {
                  name: 'integrator.address.locality',
                  label: 'Cidade',
                  minLength: 2,
                  maxLength: 50,
                },
                {
                  name: 'integrator.address.region',
                  label: 'Estado',
                  isSelect: true,
                  children: Object.entries(State).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  )),
                },
              ].map(({ children, ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  isReadOnly={isUser}
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                >
                  {children}
                </ControlledMaskedInput>
              ))}
              {!isUser && (
                <Button
                  sx={formItemStyles}
                  variant="contained"
                  component="label"
                >
                  Logo
                  <input
                    {...register('integrator.logo')}
                    hidden
                    accept="image/png"
                    type="file"
                  />
                </Button>
              )}
            </Container>
            <Container
              sx={{
                gridColumn: '2/3',
                gridRow: '2/4',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {[
                {
                  name: 'integrator.commissionValuePerMWp',
                  label: 'Valor de Comissão (R$/MWp)',
                  min: 0,
                  transform: {
                    inputValueToFormValue: inputValueToFormValue(1),
                    formValueToInputValue: formValueToInputValue(1),
                    mask: maskNumber(8),
                    unmask: unmaskNumber,
                  },
                },
                {
                  name: 'integrator.doesLocalWork',
                  label: 'Executa obra local',
                  isSelect: true,
                  isRequired: false,
                  validate: {
                    isBoolean: (value: boolean | string) => {
                      return typeof value === 'boolean' || 'Campo obrigatório';
                    },
                  },
                  transform: {
                    formValueToInputValue: (value: boolean) =>
                      typeof value === 'boolean' ? String(value) : '',
                    inputValueToFormValue: (value: string) =>
                      value === 'true'
                        ? true
                        : value === 'false'
                        ? false
                        : undefined,
                  },
                  children: [
                    { value: String(true), label: 'SIM' },
                    { value: String(false), label: 'NÃO' },
                  ].map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  )),
                },
              ].map(({ children, ...props }) => (
                <ControlledMaskedInput
                  isRequired
                  isReadOnly={isUser}
                  key={props.label}
                  {...props}
                  control={control}
                  errors={errors}
                >
                  {children}
                </ControlledMaskedInput>
              ))}
            </Container>
            {isCreateIntegratorForm && (
              <>
                <Container
                  sx={{
                    gridColumn: '2/3',
                    gridRow: '5/6',
                    alignSelf: 'center',
                    paddingBottom: '20px',
                  }}
                >
                  <Typography sx={sectionSubtitleStyles}>
                    CRIAR LOGIN
                  </Typography>
                </Container>
                <Container
                  sx={{
                    gridColumn: '2/3',
                    gridRow: '6/13',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {[
                    {
                      name: 'email',
                      label: 'Usuário (E-mail)',
                      validate: {
                        isValidEmail: (email: string) =>
                          emailValidator.validate(email) ||
                          'Insira um email válido',
                      },
                    },
                    {
                      name: 'password',
                      label: 'Senha',
                      minLength: 10,
                      type: 'password',
                    },
                    {
                      name: 'confirmPassword',
                      label: 'Confirme a senha',
                      minLength: 10,
                      type: 'password',
                      validate: {
                        passwordConfirmationMatches: (
                          confirmPassword: string,
                        ) => {
                          const password = getValues('password');
                          return (
                            password === confirmPassword ||
                            'As senhas não são iguais'
                          );
                        },
                      },
                    },
                  ].map(({ ...props }) => (
                    <ControlledMaskedInput
                      isRequired
                      isReadOnly={isUser}
                      key={props.label}
                      {...props}
                      control={control}
                      errors={errors}
                    />
                  ))}
                </Container>
              </>
            )}
          </Container>
          <Container
            sx={{
              display: 'flex',
              width: '100%',
              gap: '50px',
              justifyContent: 'center',
              marginBottom: '50px',
            }}
          >
            {!isUser && (
              <>
                <Button
                  variant="contained"
                  color="warning"
                  type="submit"
                  disabled={isLoading}
                  sx={{ width: '200px' }}
                >
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  href={config.internalPaths.adminHome}
                  disabled={isLoading}
                  sx={{ width: '200px' }}
                >
                  Cancelar
                </Button>
              </>
            )}
          </Container>
        </Box>
      </Container>
    </AppContainer>
  );
}
