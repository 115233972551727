import React from 'react';
import { Container } from '@mui/system';
import { Box, Card, Typography } from '@mui/material';
import energyPlant from '../../images/energy-plant.jpg';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { useTheme } from '@mui/material/styles';
import { LoginForm } from './form';

export function LoginPage() {
  const theme = useTheme();
  return (
    <Container
      sx={{
        width: '100vw',
        height: 'calc(100vh - 116px)',
      }}
    >
      <Header></Header>
      <Container
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            height: '400px',
            width: '740px',
            boxShadow: '0px 4px 10px 2px rgba(40, 40, 40, 0.15)',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              height: '400px',
              width: '370px',
              background: `linear-gradient(0deg, rgba(8, 41, 68, 0.7), rgba(8, 41, 68, 0.7)), url(${energyPlant})`,
              padding: '67px 63px',
              boxSizing: 'border-box',
            }}
          >
            <Typography
              sx={{
                fontSize: '2.5em',
                fontWeight: 700,
                lineHeight: '46px',
                color: theme.palette.secondary.main,
              }}
            >
              LOGIN
            </Typography>
          </Box>
          <Box
            sx={{
              height: '400px',
              width: '370px',
              background: theme.palette.primary.contrastText,
            }}
          >
            <LoginForm></LoginForm>
          </Box>
        </Card>
      </Container>
      <Footer></Footer>
    </Container>
  );
}
