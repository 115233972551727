import React from 'react';
import {
  DataGrid,
  GridCallbackDetails,
  GridEventListener,
  GridSelectionModel,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { PowerDistributionCompanyDto } from './index';
import { Container } from '@mui/system';

const defaultColumnProps = {
  width: 100,
  editable: true,
  type: 'number',
  headerClassName: 'header-styles',
  headerAlign: 'center' as 'center',
};

// @TODO: move these functions to a more adequate place
const getNestedObjectValue = (obj: any, field: string) =>
  field.split('.').reduce((lastValue: Record<string, any>, path: string) => {
    return lastValue?.[path];
  }, obj);

export const setFieldInNestedObject = ({
  obj,
  field,
  value,
}: {
  obj: any;
  field: string;
  value: any;
}) => {
  const { fieldValue } = field
    .split('.')
    .reverse()
    .reduce<{ pathsLeft: string; fieldValue: any }>(
      ({ pathsLeft, fieldValue }, path) => {
        const newFieldValue =
          typeof fieldValue !== 'object'
            ? {
                [path]: fieldValue,
              }
            : {
                [path]: {
                  ...getNestedObjectValue(obj, pathsLeft),
                  ...fieldValue,
                },
              };

        return {
          fieldValue: newFieldValue,
          pathsLeft: pathsLeft.split('.').slice(0, -1).join('.'),
        };
      },
      { pathsLeft: field, fieldValue: value },
    );

  return { ...obj, ...fieldValue };
};

const getFieldParam = (params: GridValueGetterParams) => {
  return getNestedObjectValue(params.row, params.field);
};

const setFieldParam = (field: string) => (params: GridValueSetterParams) => {
  return setFieldInNestedObject({
    field,
    obj: params.row,
    value: params.value,
  });
};

export default function PDCGrid({
  handlePageChange,
  handleCellEditCommit,
  handleAddRow,
  handleSelectionModelChange,
  handleDelete,
  rows,
  rowsPerPage,
  shouldDisableDeleteButton,
}: {
  handleSelectionModelChange: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails<any>,
  ) => void;
  handlePageChange: (page: number) => void;
  handleCellEditCommit: GridEventListener<'cellEditCommit'>;
  handleAddRow: () => void;
  handleDelete: () => void | Promise<void>;
  rows: PowerDistributionCompanyDto[];
  rowsPerPage: number;
  shouldDisableDeleteButton: boolean;
}) {
  return (
    <Box
      sx={{
        height: 725,
        width: '100%',
        paddingTop: '50px',
        '& .blue-a4': {
          backgroundColor: '#4A9DCB',
        },
        '& .blue-a4-ape': {
          backgroundColor: '#6FB6DE',
        },
        '& .green-a4': {
          backgroundColor: '#82B675',
        },
        '& .green-a4-ape': {
          backgroundColor: '#8DC77F',
        },
        '& .b3': {
          backgroundColor: '#E7C672',
        },
      }}
    >
      <DataGrid
        onSelectionModelChange={handleSelectionModelChange}
        onPageChange={handlePageChange}
        onCellEditCommit={handleCellEditCommit}
        rows={rows}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ columnGrouping: true }}
        components={{
          Toolbar: () => (
            <Container>
              <Button onClick={handleAddRow}>Adicionar linha</Button>
              <Button
                disabled={shouldDisableDeleteButton}
                onClick={handleDelete}
              >
                Deletar selecionados
              </Button>
            </Container>
          ),
        }}
        columns={[
          {
            ...defaultColumnProps,
            field: 'name',
            headerName: 'Concessionária',
            width: 150,
            type: 'string',
          },
          {
            ...defaultColumnProps,
            field: 'state',
            headerName: 'UF',
            type: 'string',
            valueParser: (value: string) => value.toUpperCase().slice(0, 2),
          },
          {
            ...defaultColumnProps,
            field: 'icms',
            headerName: 'ICMS (%)',
            valueFormatter: (params) =>
              (parseFloat(params.value) * 100).toFixed(2),
            valueParser: (value) => {
              return value < 0 ? 0 : value > 1 ? 1 : parseFloat(value);
            },
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.b3.tusd',
            headerName: 'tusd',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.b3.tusd'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.b3.te',
            headerName: 'te',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.b3.te'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.greenA4.tusdA4',
            headerName: 'tusd A4',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.greenA4.tusdA4'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.greenA4.tusdG',
            headerName: 'tusd g',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.greenA4.tusdG'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.greenA4.tusdP',
            headerName: 'tusd p',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.greenA4.tusdP'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.greenA4.teP',
            headerName: 'te p',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.greenA4.teP'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.greenA4.tusdFp',
            headerName: 'tusd fp',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.greenA4.tusdFp'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.greenA4.teFp',
            headerName: 'te fp',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.greenA4.teFp'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.greenA4.ape.tusdP',
            headerName: 'tusd p',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.greenA4.ape.tusdP'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.greenA4.ape.tusdFp',
            headerName: 'tusd fp',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.greenA4.ape.tusdFp'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.tusdA4P',
            headerName: 'tusd A4 p',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.tusdA4P'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.tusdA4Fp',
            headerName: 'tusd A4 fp',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.tusdA4Fp'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.tusdG',
            headerName: 'tusd g',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.tusdG'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.tusdP',
            headerName: 'tusd p',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.tusdP'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.teP',
            headerName: 'te p',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.teP'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.tusdFp',
            headerName: 'tusd fp',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.tusdFp'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.teFp',
            headerName: 'te fp',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.teFp'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.ape.tusdP',
            headerName: 'tusd p',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.ape.tusdP'),
          },
          {
            ...defaultColumnProps,
            field: 'electricityFees.blueA4.ape.tusdFp',
            headerName: 'tusd fp',
            valueGetter: getFieldParam,
            valueSetter: setFieldParam('electricityFees.blueA4.ape.tusdFp'),
          },
          {
            ...defaultColumnProps,
            field: 'observations',
            headerName: 'Observações',
            width: 150,
            type: 'string',
          },
        ]}
        columnGroupingModel={[
          {
            groupId: 'B3',
            children: [
              { field: 'electricityFees.b3.tusd' },
              { field: 'electricityFees.b3.te' },
            ],
            headerClassName: 'b3',
            headerAlign: 'center',
          },
          {
            groupId: 'Verde A4',
            children: [
              { field: 'electricityFees.greenA4.tusdA4' },
              { field: 'electricityFees.greenA4.tusdG' },
              { field: 'electricityFees.greenA4.tusdP' },
              { field: 'electricityFees.greenA4.teP' },
              { field: 'electricityFees.greenA4.tusdFp' },
              { field: 'electricityFees.greenA4.teFp' },
            ],
            headerClassName: 'green-a4',
            headerAlign: 'center',
          },
          {
            groupId: 'Verde A4 APE',
            children: [
              { field: 'electricityFees.greenA4.ape.tusdP' },
              { field: 'electricityFees.greenA4.ape.tusdFp' },
            ],
            headerClassName: 'green-a4-ape',
            headerAlign: 'center',
          },
          {
            groupId: 'Azul A4',
            children: [
              { field: 'electricityFees.blueA4.tusdA4P' },
              { field: 'electricityFees.blueA4.tusdA4Fp' },
              { field: 'electricityFees.blueA4.tusdG' },
              { field: 'electricityFees.blueA4.tusdP' },
              { field: 'electricityFees.blueA4.teP' },
              { field: 'electricityFees.blueA4.tusdFp' },
              { field: 'electricityFees.blueA4.teFp' },
            ],
            headerClassName: 'blue-a4',
            headerAlign: 'center',
          },
          {
            groupId: 'Azul A4 APE',
            children: [
              { field: 'electricityFees.blueA4.ape.tusdP' },
              { field: 'electricityFees.blueA4.ape.tusdFp' },
            ],
            headerClassName: 'blue-a4-ape',
            headerAlign: 'center',
          },
        ]}
      />
    </Box>
  );
}
