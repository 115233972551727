import { getApiInstance } from '../api';

export const getProposalById = async ({
  token,
  proposalId,
}: {
  token: string;
  proposalId: string;
}) => {
  const api = getApiInstance(token);
  try {
    const result = await api.get(`/proposals/${proposalId}`);

    return { success: true, data: result.data };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: `Erro ao carregar proposta. ${(error as Error).message}`,
    };
  }
};
