import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import emailValidator from 'email-validator';
import useAuth from '../../common/hooks/auth';
import useGeneralAppState from '../../common/hooks/general-app-state';

type Inputs = {
  email: string;
  password: string;
};

export function LoginForm() {
  const { setIsLoading, isLoading } = useGeneralAppState();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Inputs>();

  const { handleLogin } = useAuth();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsLoading(true);
    await handleLogin({
      email: data.email,
      password: data.password,
    });
    setIsLoading(false);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: '75px 0',
        paddingRight: '75px',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          alignItems: 'flex-end',
          justifyContent: 'space-evenly',
        }}
      >
        <TextField
          label="Usuário"
          variant="filled"
          size="small"
          {...register('email', {
            required: 'Campo obrigatório',
            validate: {
              isValidEmail: (email) =>
                emailValidator.validate(email) || 'Insira um email válido',
            },
          })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        ></TextField>
        <TextField
          label="Senha"
          variant="filled"
          type="password"
          size="small"
          {...register('password', { required: 'Campo obrigatório' })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        ></TextField>
        <Button
          variant="contained"
          color="warning"
          type="submit"
          disabled={isLoading}
        >
          Entrar
        </Button>
      </Box>
    </Box>
  );
}
