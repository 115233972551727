import axios from 'axios';
import { AdminSettingsInputs } from '../../../pages/admin-settings/index';
import { getApiInstance } from '../api';

export const saveAdminSettings = async ({
  token,
  userInput,
}: {
  token: string;
  userInput: AdminSettingsInputs;
}) => {
  const api = getApiInstance(token);
  try {
    await api.post(`/admin-settings`, userInput);

    return { success: true };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error) && error.response?.status) {
      if (error.response?.status === 400) {
        const apiMessage = error?.response?.data?.message;
        const arrayOfMessages =
          typeof apiMessage === 'string' ? [apiMessage] : apiMessage || [];
        const message = ['Erro ao salvar parâmetros.', ...arrayOfMessages].join(
          ' ',
        );
        return {
          success: false,
          message,
        };
      }
      return {
        success: false,
        message: `Erro ao salvar parâmetros. ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao salvar parâmetros. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
