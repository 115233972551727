import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

function Loading({ isLoading }: { isLoading: boolean }) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit"></CircularProgress>
    </Backdrop>
  );
}

export default Loading;
