import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import useGeneralAppState from '../../common/hooks/general-app-state';
import { IAppMessage } from '../../common/contexts/general-app-state';

const getContentWithUrl = (appMessage: IAppMessage) => {
  const [beforeUrl, url, afterUrl] = appMessage.content.split('%a');

  return (
    <p>
      {beforeUrl}
      <a href={appMessage.url} target="_blank" rel="noreferrer">
        {url}
      </a>
      {afterUrl}
    </p>
  );
};

function AlertDialog() {
  const { appMessage, setAppMessage } = useGeneralAppState();

  const handleClose = useCallback(() => {
    setAppMessage(undefined);
  }, [setAppMessage]);

  return (
    <>
      {Boolean(appMessage?.content) && (
        <Dialog open={Boolean(appMessage?.content)} onClose={handleClose}>
          <DialogContent>
            <DialogTitle>{appMessage?.title}</DialogTitle>
            <DialogContent>
              {appMessage?.url
                ? getContentWithUrl(appMessage)
                : appMessage?.content}
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default AlertDialog;
