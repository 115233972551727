import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import useAuth from '../../common/hooks/auth';
import { config } from '../../common/config/base';
import { ProposalTable } from './table';
import { AppContainer } from '../../components/app-container';
import { useParams } from 'react-router-dom';
import { getIntegratorById } from '../../common/api/integrators/get-integrator-by-id';
import useGeneralAppState from '../../common/hooks/general-app-state';

export function IntegratorProposalList() {
  const { integratorId } = useParams();
  const { setAppMessage } = useGeneralAppState();
  const { user } = useAuth();
  const theme = useTheme();
  const [pageTitle, setPageTitle] = useState('');

  const isUser = !integratorId;
  const isAdmin = !isUser;

  const getIntegrator = useCallback(async () => {
    const { success, message, data } = await getIntegratorById({
      token: user?.token as string,
      integratorId: integratorId as string,
    });

    if (success) {
      setPageTitle(data.name);
      return;
    }

    setAppMessage({
      title: 'Erro',
      content: message as string,
    });
  }, [setAppMessage, integratorId, user]);

  useEffect(() => {
    if (!user) return;

    if (isUser) {
      setPageTitle(user?.email as string);
      return;
    }

    getIntegrator();
  }, [user, isUser, getIntegrator]);

  return (
    <AppContainer isLoading={false} title={pageTitle}>
      <Container
        sx={{
          height: '100%',
        }}
      >
        <ProposalTable></ProposalTable>
        <Container sx={{ paddingTop: '25px', paddingBottom: '50px' }}>
          <Button
            disabled={isAdmin}
            variant="contained"
            sx={{
              width: '300px',
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.secondary.main,
              textAlign: 'center',
              fontSize: '1.0em',
              fontWeight: 800,
            }}
            href={config.internalPaths.createProposal}
          >
            Criar Proposta
          </Button>
        </Container>
      </Container>
    </AppContainer>
  );
}
