import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { config } from './common/config/base';
import { LandingPage } from './pages';
import useGeneralAppState from './common/hooks/general-app-state';
import AlertDialog from './components/dialog';
import PrivateRouting from './components/private-routing';
import Loading from './components/loading';

function App() {
  const { isLoading } = useGeneralAppState();

  return (
    <>
      <Router>
        <Routes>
          <Route
            path={config.internalPaths.landing}
            element={<LandingPage></LandingPage>}
          />
          <Route path="*" element={<PrivateRouting></PrivateRouting>}></Route>
        </Routes>
      </Router>
      <Loading isLoading={isLoading}></Loading>
      <AlertDialog></AlertDialog>
    </>
  );
}

export default App;
