import axios from 'axios';
import { getApiInstance } from '../api';

export const getProposalFilesUrl = async ({
  token,
  proposalId,
}: {
  token: string;
  proposalId: string;
}) => {
  const api = getApiInstance(token);
  try {
    const result = await api.get(`/proposals/files/${proposalId}`);

    return { success: true, data: result.data };
  } catch (error) {
    console.error(error);

    if (axios.isAxiosError(error) && error.response?.status) {
      return {
        success: false,
        message: `Erro ao baixar arquivos. ${error.response?.data.message}`,
      };
    }

    return {
      success: false,
      message: `Erro ao baixar arquivos. Erro desconhecido: ${
        (error as Error).message
      }`,
    };
  }
};
