import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@mui/system';
import { AppContainer } from '../../components/app-container';
import PDCGrid, { setFieldInNestedObject } from './grid';
import useAuth from '../../common/hooks/auth';
import { getAllPowerDistributionCompanies } from '../../common/api/power-distribution-companies/get-all-pdcs';
import useGeneralAppState from '../../common/hooks/general-app-state';
import {
  GridCallbackDetails,
  GridEventListener,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { config } from '../../common/config/base';
import { updatePowerDistributionCompanies } from '../../common/api/power-distribution-companies/update-pdcs';
import { v4 as uuid } from 'uuid';
import ConfirmationDialog from '../../components/confirmation-dialog/index';
import { confirmDialogInitialProps } from '../../components/confirmation-dialog/initial-props';
import { bulkDeletePowerDistributionCompanies } from '../../common/api/power-distribution-companies/bulk-delete-pdcs';

export interface PowerDistributionCompanyDto {
  id: string;
  name: string;
  state: string;
  observations: string;
  icms: number;
  electricityFees: {
    b3: {
      tusd: number;
      te: number;
    };
    greenA4: {
      tusdA4: number;
      tusdG: number;
      tusdP: number;
      teP: number;
      tusdFp: number;
      teFp: number;
      ape: {
        tusdP: number;
        tusdFp: number;
      };
    };
    blueA4: {
      tusdA4P: number;
      tusdA4Fp: number;
      tusdG: number;
      tusdP: number;
      teP: number;
      tusdFp: number;
      teFp: number;
      ape: {
        tusdP: number;
        tusdFp: number;
      };
    };
  };
}

export function PowerDistributionCompaniesPage() {
  const { user } = useAuth();
  const { setAppMessage } = useGeneralAppState();
  const [isLoading, setIsLoading] = useState(false);
  const [powerDistributionCompanies, setPowerDistributionCompanies] = useState<
    PowerDistributionCompanyDto[]
  >([]);
  const [page, setPage] = useState(0);
  const [selectedElementsIds, setSelectedElementsIds] = useState<string[]>([]);
  const [shouldDisableDeleteButton, setShouldDisableDeleteButton] =
    useState(true);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState(
    confirmDialogInitialProps,
  );

  const rowsPerPage = 10;

  const loadPowerDistributionCompanies = useCallback(async () => {
    if (!user) return;

    setIsLoading(true);
    const { success, message, data } = await getAllPowerDistributionCompanies(
      user.token,
    );
    setIsLoading(false);

    if (success) {
      setPowerDistributionCompanies(data);
    }

    setAppMessage({
      title: 'Erro',
      content: message as string,
    });
  }, [setAppMessage, user]);

  useEffect(() => {
    loadPowerDistributionCompanies();
  }, [loadPowerDistributionCompanies]);

  const handleCellEditCommit: GridEventListener<'cellEditCommit'> = useCallback(
    (params) => {
      const { id, field, value: newFieldValue } = params;

      setPowerDistributionCompanies((oldPdcs) => {
        const position = oldPdcs.findIndex((row) => row.id === id);
        const row = oldPdcs[position];
        const updatedPdc = setFieldInNestedObject({
          obj: row,
          field,
          value: newFieldValue,
        }) as PowerDistributionCompanyDto;
        return [
          ...oldPdcs.slice(0, position),
          updatedPdc,
          ...oldPdcs.slice(position + 1),
        ];
      });
    },
    [],
  );

  const handleUpdate = useCallback(
    async (data: PowerDistributionCompanyDto[]) => {
      setIsLoading(true);
      const { success, message } = await updatePowerDistributionCompanies({
        token: user?.token as string,
        userInput: data,
      });
      setIsLoading(false);

      if (success) {
        setAppMessage({
          title: 'Sucesso',
          content: 'As concessionárias foram atualizadas com sucesso.',
        });
        return;
      }

      setAppMessage({ title: 'Erro', content: message as string });
    },
    [setAppMessage, user, setIsLoading],
  );

  const onSubmit = () => {
    if (!user) return;

    handleUpdate(powerDistributionCompanies);
  };

  const handleAddRow = () => {
    const newRowPosition = page * rowsPerPage;
    setPowerDistributionCompanies((value) => [
      ...value.slice(0, newRowPosition),
      { id: uuid(), icms: 0 } as PowerDistributionCompanyDto,
      ...value.slice(newRowPosition),
    ]);
  };

  const handlePageChange = (page: number) => setPage(page);

  const handleSelectionModelChange = (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails<any>,
  ) => {
    setSelectedElementsIds(selectionModel as string[]);
    if (selectionModel.length > 0) {
      setShouldDisableDeleteButton(false);
      return;
    }
    setShouldDisableDeleteButton(true);
  };

  const handleDelete = useCallback(async () => {
    setConfirmationDialogProps(confirmDialogInitialProps);

    setIsLoading(true);
    const { success, message } = await bulkDeletePowerDistributionCompanies({
      token: user?.token as string,
      ids: selectedElementsIds,
    });
    setIsLoading(false);

    if (success) {
      setAppMessage({
        title: 'Sucesso',
        content: 'Concessionárias deletas com sucesso.',
      });
      await loadPowerDistributionCompanies();
      return;
    }

    setAppMessage({
      title: 'Erro',
      content: message as string,
    });
  }, [
    setAppMessage,
    user,
    selectedElementsIds,
    loadPowerDistributionCompanies,
  ]);

  const handleConfirmDelete = useCallback(() => {
    const numberOfPdcsToDelete = selectedElementsIds.length;
    const pdcsTerm =
      numberOfPdcsToDelete > 1 ? 'concessionárias' : 'concessionária';
    setConfirmationDialogProps({
      isVisible: true,
      title: 'Atenção',
      message: `Tem certeza que deseja deletar ${numberOfPdcsToDelete} ${pdcsTerm}? Esta ação não pode ser revertida.`,
      handleReject: () => setConfirmationDialogProps(confirmDialogInitialProps),
      handleConfirm: () => handleDelete(),
    });
  }, [handleDelete, selectedElementsIds]);

  return (
    <AppContainer isLoading={isLoading} title={'concessionárias'.toUpperCase()}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PDCGrid
          rowsPerPage={rowsPerPage}
          rows={powerDistributionCompanies}
          handleCellEditCommit={handleCellEditCommit}
          handleAddRow={handleAddRow}
          handlePageChange={handlePageChange}
          handleSelectionModelChange={handleSelectionModelChange}
          shouldDisableDeleteButton={shouldDisableDeleteButton}
          handleDelete={handleConfirmDelete}
        ></PDCGrid>
        <Container
          sx={{
            display: 'flex',
            width: '100%',
            gap: '50px',
            justifyContent: 'center',
            marginTop: '50px',
            marginBottom: '100px',
          }}
        >
          <Button
            variant="contained"
            color="warning"
            disabled={isLoading}
            sx={{ width: '200px' }}
            onClick={onSubmit}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            href={config.internalPaths.adminHome}
            disabled={isLoading}
            sx={{ width: '200px' }}
          >
            Cancelar
          </Button>
        </Container>
        <ConfirmationDialog {...confirmationDialogProps}></ConfirmationDialog>
      </Container>
    </AppContainer>
  );
}
