import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ProposalType, Role } from '../../../common/enums';
import { AppContainer } from '../../../components/app-container';
import { SelectProposalType } from './select-proposal-type';
import { CustomerData } from './customer-data';
import { ProposalInput } from './input.interface';
import { TechnicalAndCommercialData } from './technical-and-commercial-data/index';
import { FinancialData } from './financial-data';
import { createProposal } from '../../../common/api/proposals/create-proposal';
import useAuth from '../../../common/hooks/auth';
import useGeneralAppState from '../../../common/hooks/general-app-state';
import { config } from '../../../common/config/base';
import { getProposalById } from '../../../common/api/proposals/get-by-id';
import { updateProposal } from '../../../common/api/proposals/update-proposal';
import { uploadProposalFiles } from '../../../common/api/proposals/upload-proposal-files';
import { getAccessPermissions } from '../../../common/api/admin-settings/get-access-permissions';
import { getProposalDefaultParams } from '../../../common/api/proposals/get-default-params';

export enum SaveType {
  CREATE,
  UPDATE,
}

export function ProposalForm() {
  const { proposalId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setAppMessage } = useGeneralAppState();
  const [isLoading, setIsLoading] = useState(false);
  const [proposalType, setProposalType] = useState<ProposalType>();
  const [allowedProposalTypes, setAllowedProposalTypes] =
    useState<ProposalType[]>();
  const [isLocalProject, setIsLocalProject] = useState(false);
  const isCreatingProposal = !proposalId;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<ProposalInput>({
    defaultValues: async () => {
      setIsLoading(true);
      if (isCreatingProposal) {
        const { success, data } = await getProposalDefaultParams(
          user?.token as string,
        );
        setIsLoading(false);
        if (!success) {
          return {};
        }
        return {
          loan: {
            annualInterestRate: data.loanAnnualInterestRate,
            downPaymentPercentage: data.loanDownPaymentPercentage,
          },
        };
      }

      const { success, message, data } = await getProposalById({
        token: user?.token as string,
        proposalId,
      });
      setIsLoading(false);

      if (success) {
        setProposalType(data.proposalType);
        return {
          ...data,
          consumerUnit: {
            ...data.consumerUnit,
            powerDistributionCompany:
              data.consumerUnit.powerDistributionCompany,
          },
          loan: {
            numberOfMonthlyPayments: data.loanNumberOfMonthlyPayments,
            annualInterestRate: data.loanAnnualInterestRate,
            downPaymentPercentage: data.loanDownPaymentPercentage,
            gracePeriodInMonths: data.loanGracePeriodInMonths,
          },
        };
      }

      setAppMessage({
        title: 'Erro',
        content: message as string,
      });
      navigate(config.internalPaths.proposal);
    },
  });

  const loadAllowedProposalTypes = useCallback(async () => {
    setIsLoading(true);
    const { success, message, data } = await getAccessPermissions(
      user?.token as string,
    );
    setIsLoading(false);

    if (success) {
      return setAllowedProposalTypes(data.allowedProposalTypes);
    }

    setAppMessage({
      title: 'Erro',
      content: message as string,
    });
    navigate(config.internalPaths.proposal);
  }, [navigate, setAppMessage, setIsLoading, user]);

  useEffect(() => {
    loadAllowedProposalTypes();
  }, [loadAllowedProposalTypes]);

  useEffect(() => {
    const isLocal = proposalType === ProposalType.LOCAL_DEVELOPMENT;
    setIsLocalProject(isLocal);
    setValue('proposalType', proposalType as ProposalType);
  }, [proposalType, setValue]);

  const handleCreateProposal = useCallback(
    async (data: Omit<ProposalInput, 'files'>) => {
      setIsLoading(true);
      const {
        success,
        message,
        data: createdProposal,
      } = await createProposal({
        token: user?.token as string,
        userInput: data,
      });
      setIsLoading(false);

      if (success) {
        return createdProposal.id;
      }

      throw new Error(message as string);
    },
    [user, setIsLoading],
  );

  const handleUpdate = useCallback(
    async (data: ProposalInput) => {
      const { id, ...userInput } = data;

      setIsLoading(true);
      const { success, message } = await updateProposal({
        token: user?.token as string,
        proposalId: proposalId as string,
        userInput,
      });
      setIsLoading(false);

      if (success) {
        return;
      }

      throw new Error(message as string);
    },
    [proposalId, user, setIsLoading],
  );

  const handleUploadFiles = useCallback(
    async ({
      files,
      proposalId,
      type,
    }: {
      files: FileList;
      proposalId: string;
      type: SaveType;
    }) => {
      setIsLoading(true);
      const { success, message } = await uploadProposalFiles({
        token: user?.token as string,
        files,
        proposalId,
      });
      setIsLoading(false);

      const term = type === SaveType.UPDATE ? 'atualizada' : 'criada';
      const complement =
        type === SaveType.UPDATE
          ? ''
          : 'Agora você já pode vê-la na lista de propostas.';

      if (success) {
        return {
          title: 'Sucesso',
          content: `Sua proposta foi ${term} com sucesso. ${complement}`,
        };
      }

      return {
        title: 'Erro',
        content: `Sua proposta foi ${term} com sucesso. Porém houve um erro ao salvar os arquivos anexados.
          O servidor nos enviou o seguinte erro: ${message}.`,
      };
    },
    [user, setIsLoading],
  );

  const isAdmin = user?.role === Role.ADMIN;
  const isReadOnly = isAdmin;

  const pageTitle = (
    isAdmin
      ? 'visualizar proposta'
      : !!proposalId
      ? 'editar proposta'
      : 'criar proposta'
  ).toUpperCase();

  if (isLoading || (isCreatingProposal && !allowedProposalTypes)) {
    return (
      <AppContainer isLoading={true} title={pageTitle}>
        <></>
      </AppContainer>
    );
  }

  if (!proposalId && !proposalType) {
    return (
      <AppContainer isLoading={isLoading} title={pageTitle}>
        <SelectProposalType setProposalType={setProposalType} />
      </AppContainer>
    );
  }

  if (
    isCreatingProposal &&
    !allowedProposalTypes?.includes(proposalType as ProposalType)
  ) {
    setProposalType(undefined);
    setAppMessage({
      title: 'Conteúdo indisponível',
      content:
        'Desculpe-nos a inconveniência. No momento, este tipo de proposta não está disponível. Tente novamente mais tarde.',
    });
    return (
      <AppContainer isLoading={isLoading} title={pageTitle}>
        <SelectProposalType setProposalType={setProposalType} />
      </AppContainer>
    );
  }

  const validateFiles = (files: FileList) => {
    const fileSizeLimit = 10485760; //10MB
    const fileQuantityLimit = 2;

    if (files.length === 0) {
      return false;
    }

    if (files.length > 2) {
      throw new Error(
        `O número de arquivos carregados, ${files.length}, é maior que o máximo permitido, ${fileQuantityLimit}.
        Carregue somente as faturas da sua conta de energia e da conta da distribuidora`,
      );
    }

    for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
      const file = files[fileIndex];
      if (fileSizeLimit < file.size) {
        throw new Error(
          `${file.name} é maior que o tamanho máximo permitido de 10MB`,
        );
      }
    }

    return true;
  };

  const onSubmit = async (data: ProposalInput) => {
    try {
      const { files, ...proposal } = data;
      const hasFiles = validateFiles(files);

      if (isCreatingProposal && hasFiles === false) {
        throw new Error(
          'Carregue os arquivos das faturas da sua conta de energia e da conta da distribuidora.',
        );
      }

      if (isCreatingProposal) {
        const proposalId = await handleCreateProposal(proposal);
        const { title, content } = await handleUploadFiles({
          files,
          proposalId,
          type: SaveType.CREATE,
        });
        setAppMessage({
          title,
          content,
        });
        return navigate(config.internalPaths.proposal);
      }

      await handleUpdate(data);

      if (hasFiles) {
        const { title, content } = await handleUploadFiles({
          files,
          proposalId,
          type: SaveType.UPDATE,
        });

        setAppMessage({
          title,
          content,
        });
        return navigate(config.internalPaths.proposal);
      }

      setAppMessage({
        title: 'Sucesso',
        content: 'Sua proposta foi atualizada com sucesso.',
      });
      return navigate(config.internalPaths.proposal);
    } catch (error) {
      setAppMessage({
        title: 'Erro',
        content: (error as Error).message,
      });
    }
  };

  return (
    <AppContainer isLoading={isLoading} title={pageTitle}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CustomerData
          control={control}
          errors={errors}
          isReadOnly={isReadOnly}
        />
        <TechnicalAndCommercialData
          register={register}
          control={control}
          proposalType={proposalType as ProposalType}
          isLocalProject={isLocalProject}
          errors={errors}
          isReadOnly={isReadOnly}
        />
        <FinancialData
          isReadOnly={isReadOnly}
          control={control}
          getValues={getValues}
          errors={errors}
        />
        <Container
          sx={{
            visibility: isReadOnly ? 'hidden' : 'visible',
            display: 'flex',
            width: '100%',
            gap: '50px',
            justifyContent: 'center',
            marginBottom: '50px',
          }}
        >
          <Button
            variant="contained"
            color="warning"
            type="submit"
            disabled={isLoading}
            sx={{ width: '200px' }}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            href={config.internalPaths.proposal}
            disabled={isLoading}
            sx={{ width: '200px' }}
          >
            Cancelar
          </Button>
        </Container>
      </Box>
    </AppContainer>
  );
}
